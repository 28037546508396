import React, { useContext, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-kit-react/views/dashboard/admins/editDescription.js";

import * as api from "../api/index.js";
import appsettings from "appsettings.json";
import { ClientsContext } from "../root/Store.js";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function EditDescriptionModal(props) {
  const [clients] = useContext(ClientsContext);
  const client = clients.data.find(c => c.name == appsettings.appName);
  const [description, setDescription] = useState(client && client.description);
  const classes = useStyles();
  // eslint-disable-next-line react/prop-types
  const { isVisible, setVisible } = props;
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} lg={4}>
            <Dialog
              classes={{
                root: classes.center,
                paper: classes.modal
              }}
              open={isVisible}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => setVisible(false)}
              aria-labelledby="classic-modal-slide-title"
              aria-describedby="classic-modal-slide-description"
            >
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <IconButton
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={() => setVisible(false)}
                >
                  <Close className={classes.modalClose} />
                </IconButton>
                <h4 align={"center"} className={classes.modalTitle}>
                  Edit Description
                </h4>
              </DialogTitle>
              <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
              >
                {clients && (
                  <CustomInput
                    labelText="Desciption..."
                    id="description"
                    value={description}
                    onChangeValue={value => setDescription(value)}
                    formControlProps={{
                      fullWidth: true
                    }}
                    rows={10}
                    multiline={true}
                  />
                )}
              </DialogContent>
              <DialogActions className={classes.modalFooter}>
                <Button
                  color="success"
                  simple
                  onClick={() => {
                    api.update("clients", client.id, {
                      ...client,
                      description: description
                    });
                    setVisible(false);
                  }}
                >
                  Update
                </Button>
                <Button onClick={() => setVisible(false)} color="danger" simple>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
