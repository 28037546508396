import * as api from "api/emailApi.js";
import appsettings from "appsettings.json";
import construcApplicationDetailsEmailBody from "./convertToHtmlTable.js";
import { toBase64 } from "shared/utils/files";

export const sendApplicationConfirmationEmail = application => {
  var message =
    "Hi " +
    application.studentDetails.firstNames +
    "\n\n" +
    "This is a confirmation email that we have recieved your application for course: " +
    application.course.name +
    "\n" +
    "The team will be in touch with you soon." +
    "\n\n" +
    "Kind Regards" +
    "\n" +
    "FFSA Team";

  api.sendMail({
    to: application.studentDetails.email,
    message: {
      subject: "Application Confirmation",
      text: message
    }
  });
};

export const sendApplicationAwaitingPaymentEmail = application => {
  var message =
    "Hi " +
    application.studentDetails.firstNames +
    "<br><br>" +
    "This is a notification email regarding your application for course: " +
    application.course.name +
    "<br>" +
    "The application status is awaiting payment please make payments here <a href='www.ffsaza.co.za/applications'>applications</a>.<br>" +
    "<br><br>" +
    "Kind Regards" +
    "<br>" +
    "FFSA Team";

  api.sendMail({
    to: application.studentDetails.email,
    message: {
      subject: "Application Awaiting Payment",
      html: message
    }
  });
};

export const sendApplicationForAdmission = (application, subject) => {
  var emailBody = construcApplicationDetailsEmailBody(application);
  api.sendMail({
    to: appsettings[appsettings.environment].sendToEmail,
    message: {
      subject: subject,
      html: emailBody
    }
  });
};

export const sendApplicationForAdmissionToAdmin = (application, subject) => {
  var emailBody = construcApplicationDetailsEmailBody(application);
  api.sendMail({
    to: appsettings[appsettings.environment].sendToEmail,
    message: {
      subject: subject,
      html: emailBody
    }
  });
};

export const sendApplicationFeeReceiptEmail = async (
  application,
  fee,
  file
) => {
  var message =
    "Hi Team" +
    "\n\n" +
    "This is a fee receipt upload notification with the details below." +
    "\n\n" +
    `Student name: ${application.studentDetails.firstNames} ${application.studentDetails.surname}` +
    "\n" +
    `Course name: ${application.course.name}` +
    "\n" +
    `Fee amount: R${fee.amount}` +
    "\n" +
    `Fee name: ${fee.name}` +
    "\n\n" +
    "Kind Regards" +
    "\n" +
    "FFSA Team";

  api.sendMail({
    to: appsettings[appsettings.environment].sendToEmail,
    cc: application.studentDetails.email,
    message: {
      subject: `Fee Receipt: ${fee.name}`,
      text: message,
      attachments: [
        {
          filename: file.name,
          path: await toBase64(file),
          contentType: file.type
        }
      ]
    }
  });
};
