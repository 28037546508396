import React, { useContext, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/dashboard/admins/staffPage.js";
import placeholder from "assets/img/faces/user.png";

import View from "./View.js";
import { UsersContext } from "views/Dashboard/root/Store.js";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function Staff() {
  const [users] = useContext(UsersContext);
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const [isProfileVisible, setProfileVisible] = useState(false);
  const [profile, setProfile] = useState({});

  const data = users.data.filter(u => !u.isAdmin);

  const profileView = profile => {
    setProfile(profile);
    setProfileVisible(true);
  };

  return (
    <div className={classes.section}>
      <div className={classes.container} style={{ maxHeight: "500px" }}>
        <View
          student={profile}
          setVisible={setProfileVisible}
          isVisible={isProfileVisible}
        />
        {data
          .sort((a, b) => b.createdAt - a.createdAt)
          .map((student, key) => (
            <GridContainer
              key={key}
              style={{ marginBottom: "10px" }}
              onClick={() => profileView(student)}
              flex
            >
              <GridItem xs={4} sm={4} md={4} lrg={4}>
                <img
                  src={student.avatar == "" ? placeholder : student.avatar}
                  alt="..."
                  className={imageClasses}
                />
              </GridItem>
              <GridItem
                xs={6}
                sm={6}
                md={6}
                lrg={6}
                row
                style={{ paddingTop: "10px" }}
              >
                <strong>
                  {student.name} {student.surname}
                </strong>
                <br />
                <span style={{ fontSize: "80%" }}>{student.email}</span>
              </GridItem>
            </GridContainer>
          ))}
        <div center style={{ marginTop: 10 }}>
          {data.length == 0 && "No students."}
        </div>
      </div>
    </div>
  );
}
