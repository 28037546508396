import React, { useReducer, useEffect } from "react";
import firebase from "firebase";

import * as api from "api/index.js";
import appsettings from "appsettings.json";
import * as rootReducer from "shared/utils/rootReducer";

export const UsersContext = React.createContext();
export const DocumentsContext = React.createContext();
export const CoursesContext = React.createContext();
export const AnnouncementsContext = React.createContext();
export const MessagesContext = React.createContext();
export const StudentCourseContext = React.createContext();
export const ApplicationsContext = React.createContext();
export const FeesContext = React.createContext();
export const PaymentsContext = React.createContext();
export const ReceiptsContext = React.createContext();

const initalState = {
  data: [],
  search: "",
  inProgress: true
};

if (!firebase.apps.length)
  firebase.initializeApp(appsettings[appsettings.environment].firebaseConfig);

// eslint-disable-next-line react/prop-types
const Store = ({ children }) => {
  const [users, dispatchUsers] = useReducer(
    rootReducer.setStateReducer,
    initalState
  );
  const [documents, dispatchDocuments] = useReducer(
    rootReducer.setStateReducer,
    initalState
  );
  const [courses, dispatchCourses] = useReducer(
    rootReducer.setStateReducer,
    initalState
  );
  const [announcemnets, dispatchAnnouncements] = useReducer(
    rootReducer.setStateReducer,
    initalState
  );
  const [messages, dispatchMessages] = useReducer(
    rootReducer.setStateReducer,
    initalState
  );
  const [studentCourse, dispatchStudentCourse] = useReducer(
    rootReducer.setStateReducer,
    initalState
  );
  const [applications, dispatchApplications] = useReducer(
    rootReducer.setStateReducer,
    initalState
  );
  const [fees, dispatchFees] = useReducer(
    rootReducer.setStateReducer,
    initalState
  );
  const [payments, dispatchPayments] = useReducer(
    rootReducer.setStateReducer,
    initalState
  );
  const [receipts, dispatchReceipts] = useReducer(
    rootReducer.setStateReducer,
    initalState
  );

  useEffect(() => {
    api.getCollection("users", dispatchUsers);
    api.getCollection("documents", dispatchDocuments);
    api.getCollection("courses", dispatchCourses);
    api.getCollection("announcements", dispatchAnnouncements);
    api.getCollection("messages", dispatchMessages);
    api.getCollection("studentCourse", dispatchStudentCourse);
    api.getCollection("applications", dispatchApplications);
    api.getCollection("fees", dispatchFees);
    api.getCollection("payments", dispatchPayments);
    api.getCollection("receipts", dispatchReceipts);
  }, []);

  return (
    <UsersContext.Provider value={[users, dispatchUsers]}>
      <DocumentsContext.Provider value={[documents, dispatchDocuments]}>
        <CoursesContext.Provider value={[courses, dispatchCourses]}>
          <AnnouncementsContext.Provider
            value={[announcemnets, dispatchAnnouncements]}
          >
            <MessagesContext.Provider value={[messages, dispatchMessages]}>
              <StudentCourseContext.Provider
                value={[studentCourse, dispatchStudentCourse]}
              >
                <ApplicationsContext.Provider
                  value={[applications, dispatchApplications]}
                >
                  <FeesContext.Provider value={[fees, dispatchFees]}>
                    <PaymentsContext.Provider
                      value={[payments, dispatchPayments]}
                    >
                      <ReceiptsContext.Provider
                        value={[receipts, dispatchReceipts]}
                      >
                        {children}
                      </ReceiptsContext.Provider>
                    </PaymentsContext.Provider>
                  </FeesContext.Provider>
                </ApplicationsContext.Provider>
              </StudentCourseContext.Provider>
            </MessagesContext.Provider>
          </AnnouncementsContext.Provider>
        </CoursesContext.Provider>
      </DocumentsContext.Provider>
    </UsersContext.Provider>
  );
};
export default Store;
