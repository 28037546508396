import firebase from "firebase";
import * as DocumentPicker from "expo-document-picker";

import * as api from "api/index.js";
import appsettings from "appsettings.json";

if (!firebase.apps.length)
  firebase.initializeApp(appsettings[appsettings.environment].firebaseConfig);

const getResourceUrl = async (file, location) => {
  var snapshot = await firebase
    .storage()
    .ref()
    .child(location)
    .put(file);

  return snapshot.ref.getDownloadURL();
};

export const pickFeeSchedule = async setData => {
  setData({ type: "setInProgress", inProgress: true });

  var result = await DocumentPicker.getDocumentAsync({
    type: "application/pdf",
    multiple: false
  });

  if (result.type != "cancel") {
    api.updateJSONData("remote-config", {
      feeSchedule: await getResourceUrl(
        result.file,
        appsettings.environment + "/documents/" + result.file.name.split(".")[0]
      )
    });
    setData({ type: "setInProgress", inProgress: false });
  } else setData({ type: "setInProgress", inProgress: false });
};

export const pick = async (parentId, dispath) => {
  dispath({ type: "setInProgress", inProgress: true });

  var result = await DocumentPicker.getDocumentAsync({
    type: "application/pdf",
    multiple: false
  });

  if (result.type != "cancel") {
    const docName = result.file.name.split(".")[0];
    api.setData("documents", {
      name: docName,
      isLocked: true,
      url: await getResourceUrl(
        result.file,
        appsettings.environment + "/documents/" + docName
      ),
      parentId: parentId
    });
    dispath({ type: "setInProgress", inProgress: false });
  } else dispath({ type: "setInProgress", inProgress: false });
};

/*
const publishMultiple = (result, parentId) => {
  return new Promise(() =>
    Object.entries(result.output).map(async file => {
      const docName = result.name.split(".")[0];
      setTimeout(async () => {
        api.setData("documents", {
          name: docName,
          isLocked: true,
          url: await getResourceUrl(
            file[1],
            appsettings.environment + "/documents/" + docName
          ),
          parentId: parentId
        });
      }, 15000);
    })
  );
};
*/
