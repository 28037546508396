/* eslint-disable react/prop-types */
import React, { useContext } from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudUploadOutlined, AccountCircle } from "@material-ui/icons";

// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { CurrentUserContext, RemoteConfigContext } from "root/Store.js";
import * as documentPicker from "shared/utils/documentPicker";
import { signOut } from "api/authApi";
import saflag from "assets/img/saflag.png";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const [currentUser] = useContext(CurrentUserContext);
  const [remoteConfig, setRemoteConfig] = useContext(RemoteConfigContext);
  const [isCloutUploadVisible, setCloudUploadVisible] = React.useState(false);

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Button
          onClick={() => props.history.push("/courses-offered")}
          color="transparent"
          className={classes.navLink}
        >
          Courses
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          onClick={() => props.history.push("/campuses")}
        >
          Campuses
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          onClick={() => props.history.push("/student-life")}
        >
          Student Life
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          onClick={() => props.history.push("/team")}
        >
          Team
        </Button>
      </ListItem>

      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          onClick={() => props.history.push("/affiliations")}
        >
          Affiliations
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          onClick={() => props.history.push("/contact")}
        >
          Contact
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <div
          onMouseEnter={() => setCloudUploadVisible(true)}
          onMouseLeave={() => setCloudUploadVisible(false)}
        >
          <Button
            disabled={remoteConfig.inProgress}
            color="transparent"
            onClick={() => {
              if (!remoteConfig.inProgress) {
                remoteConfig.data && remoteConfig.data.feeSchedule
                  ? window.open(remoteConfig.data.feeSchedule, "_blank")
                  : toast.info("Fee Schedule unavailable.");
              }
            }}
            className={classes.navLink}
          >
            {isCloutUploadVisible && currentUser && currentUser.isAdmin && (
              <CloudUploadOutlined
                className={classes.icons}
                onClick={event => {
                  if (currentUser && currentUser.isAdmin) {
                    event.stopPropagation();
                    documentPicker.pickFeeSchedule(setRemoteConfig);
                  }
                }}
              />
            )}
            {remoteConfig.inProgress ? "Loading..." : "Fees"}
          </Button>
        </div>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button color="transparent" className={classes.navLink}>
          <div
            style={{
              height: "20px",
              width: "30px",
              backgroundSize: "cover",
              backgroundImage: "url(" + saflag + ")"
            }}
          />
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip title="Dashboard" classes={{ tooltip: classes.tooltip }}>
          <Link to="/dashboard" color="transparent" className={classes.navLink}>
            <Apps className={classes.icons} />
          </Link>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          title={currentUser ? "Logout" : "Login"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            onClick={() => {
              if (currentUser) {
                signOut();
                toast.success("Signed out successfully");
                window.location.reload();
                // eslint-disable-next-line react/prop-types
              } else props.history.push("/login");
            }}
            className={classes.navLink}
          >
            <AccountCircle className={classes.icons} />
            {currentUser ? currentUser.name : "Sign in"}
          </Button>
        </Tooltip>
      </ListItem>
    </List>
  );
}
