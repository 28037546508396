import firebase from "firebase";

import appsettings from "appsettings.json";

export const uploadReceipt = async (reciept, subfolder) => {
  const snapshot = await firebase
    .storage()
    .ref()
    .child(appsettings.environment + `/reciepts/${subfolder}/` + reciept.name)
    .put(reciept);

  return await snapshot.ref.getDownloadURL();
};

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});