import React, { useState, useEffect, useReducer } from "react";
import firebase from "firebase";

import appsettings from "appsettings.json";
import * as rootReducer from "shared/utils/rootReducer";
import * as api from "api/index.js";

import Registration from "views/LandingPage/Sections/Registration/root/Store.js";
import DashBoard from "views/Dashboard/root/Store.js";
import Courses from "views/Dashboard/Sections/Courses/root/Store.js";
import Admins from "views/Dashboard/Sections/Admins/root/Store.js";

export const CurrentUserContext = React.createContext();
export const RemoteConfigContext = React.createContext();

if (!firebase.apps.length)
  firebase.initializeApp(appsettings[appsettings.environment].firebaseConfig);

// eslint-disable-next-line react/prop-types
const Store = ({ children }) => {
  const [currentUser, setUser] = useState();
  const [remoteConfig, setRemoteConfig] = useReducer(
    rootReducer.setStateReducer,
    { inProgress: true }
  );
  useEffect(() => {
    api.getJSONData("remote-config", setRemoteConfig);

    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        const userId = user.email.replace(/[^0-9a-z]/gi, "");
        api.getData("users", userId, setUser);
      }
    });
  }, []);

  return (
    <CurrentUserContext.Provider value={[currentUser, setUser]}>
      <RemoteConfigContext.Provider value={[remoteConfig, setRemoteConfig]}>
        <Registration>
          <DashBoard>
            <Admins>
              <Courses>{children}</Courses>
            </Admins>
          </DashBoard>
        </Registration>
      </RemoteConfigContext.Provider>
    </CurrentUserContext.Provider>
  );
};
export default Store;
