/* eslint-disable react/prop-types */
import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-kit-react/views/dashboard/admins/editDescription.js";

import * as api from "api/index.js";
import colors from "shared/constants/colors.js";
import { toast } from "react-toastify";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function Edit(props) {
  const classes = useStyles();
  const { isVisible, setVisible, course, setCourse } = props;
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} lg={4}>
            <Dialog
              classes={{
                root: classes.center,
                paper: classes.modal
              }}
              open={isVisible}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => setVisible(false)}
              aria-labelledby="classic-modal-slide-title"
              aria-describedby="classic-modal-slide-description"
            >
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <IconButton
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={() => setVisible(false)}
                >
                  <Close className={classes.modalClose} />
                </IconButton>
                <h4 align={"center"} className={classes.modalTitle}>
                  Update Course
                </h4>
              </DialogTitle>
              <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
              >
                <CustomInput
                  id="name"
                  value={course.name}
                  onChangeValue={value => setCourse({ ...course, name: value })}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
                <CustomInput
                  id="duration"
                  value={course.duration}
                  onChangeValue={value =>
                    setCourse({ ...course, duration: value })
                  }
                  formControlProps={{
                    fullWidth: true
                  }}
                />
                <CustomInput
                  id="description"
                  value={course.description}
                  onChangeValue={value =>
                    setCourse({ ...course, description: value })
                  }
                  formControlProps={{
                    fullWidth: true
                  }}
                  rows={10}
                  multiline={true}
                />
                <div className={classes.title}>
                  <h3>
                    <small>
                      Pick background color
                      {course.color && " : " + course.color}
                    </small>
                  </h3>
                </div>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={12}>
                    <Button
                      style={{ backgroundColor: colors.pink }}
                      onClick={() => setCourse({ ...course, color: "pink" })}
                    >
                      Pink
                    </Button>
                    <Button
                      style={{ backgroundColor: colors.golden }}
                      onClick={() => setCourse({ ...course, color: "golden" })}
                    >
                      Golden
                    </Button>
                    <Button
                      style={{ backgroundColor: colors.green }}
                      onClick={() => setCourse({ ...course, color: "green" })}
                    >
                      Green
                    </Button>
                    <Button
                      style={{ backgroundColor: colors.gray }}
                      onClick={() => setCourse({ ...course, color: "gray" })}
                    >
                      Gray
                    </Button>
                    <Button
                      style={{ backgroundColor: colors.blue }}
                      onClick={() => setCourse({ ...course, color: "blue" })}
                    >
                      Blue
                    </Button>
                    <Button
                      style={{ backgroundColor: colors.darkBlue }}
                      onClick={() =>
                        setCourse({ ...course, color: "darkBlue" })
                      }
                    >
                      Dark Blue
                    </Button>
                    <Button
                      style={{ backgroundColor: colors.lightBlue }}
                      onClick={() =>
                        setCourse({ ...course, color: "lightBlue" })
                      }
                    >
                      Light Blue
                    </Button>
                  </GridItem>
                </GridContainer>
              </DialogContent>
              <DialogActions className={classes.modalFooter}>
                <Button
                  color="success"
                  simple
                  onClick={() => {
                    api.update("courses", course.id, course);
                    setVisible(false);
                    toast.success("course updated successfully");
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="danger"
                  simple
                  onClick={() => {
                    api.removeData("courses", course.id);
                    setVisible(false);
                    toast.success("course deleted successfully");
                  }}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
