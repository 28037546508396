import React, { useReducer, useEffect } from "react";
import firebase from "firebase";

import * as api from "api/index.js";
import appsettings from "appsettings.json";
import * as rootReducer from "shared/utils/rootReducer";

export const MarketingMediaOptionsContext = React.createContext();

const initalState = {
  data: [],
  search: "",
  inProgress: true
};

if (!firebase.apps.length)
  firebase.initializeApp(appsettings[appsettings.environment].firebaseConfig);

// eslint-disable-next-line react/prop-types
const Store = ({ children }) => {
  const [marketingMediaOptions, dispatchMarketingMediaOptions] = useReducer(
    rootReducer.setStateReducer,
    initalState
  );

  useEffect(() => {
    api.getCollection("marketing-media-options", dispatchMarketingMediaOptions);
  }, []);

  return (
    <MarketingMediaOptionsContext.Provider
      value={[marketingMediaOptions, dispatchMarketingMediaOptions]}
    >
      {children}
    </MarketingMediaOptionsContext.Provider>
  );
};
export default Store;
