import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/contactStyle.js";

import appsettings from "appsettings.json";
import LocationDetails from "./Location Details/LocationDetails";
import HeaderFooter from "./HeaderFooter.js";

import * as api from "api/emailApi.js";

const useStyles = makeStyles(styles);

export default function Contact(props) {
  const [email, setEmail] = useState({});
  const classes = useStyles();

  return (
    <HeaderFooter {...props}>
      <div id="contact" className={classes.section}>
        <GridItem xs={12} sm={12} md={12} className={classes.locationdetails}>
          <h2 className={classes.title}>Get in touch</h2>
          <LocationDetails />
        </GridItem>
        <br />
        <br />
        <br />
        <GridContainer justify="center">
          <GridItem xs={10} sm={12} md={9}>
            <h2 className={classes.title}>Campuses in provinces</h2>
            <div>
              <GridItem style={customeStyles.campuse}>Kwazulu Natal</GridItem>
            </div>
          </GridItem>
        </GridContainer>
        <br />
        <br />
        <br />
        <GridContainer justify="center" className={classes.section}>
          <GridItem xs={10} sm={12} md={9}>
            <h4 className={classes.description}>
              Be a part of the first Fashion Academy in KwaMashu
              (Durban,KwaZulu-Natal) Enquire NOW about our courses!
            </h4>
          </GridItem>
          <GridItem xs={10} sm={12} md={8}>
            <form>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Your Name"
                    id="name"
                    value={email.name}
                    onChangeValue={name => setEmail({ ...email, name: name })}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Your Email"
                    id="email"
                    value={email.email}
                    onChangeValue={mail => setEmail({ ...email, email: mail })}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
                <CustomInput
                  labelText="Your Message"
                  id="message"
                  formControlProps={{
                    fullWidth: true,
                    className: classes.textArea
                  }}
                  value={email.message}
                  onChangeValue={message =>
                    setEmail({ ...email, message: message })
                  }
                  inputProps={{
                    multiline: true,
                    rows: 5
                  }}
                />
                <GridItem xs={12} sm={12} md={4}>
                  <Button
                    color="primary"
                    onClick={() => {
                      api.sendMail({
                        to: appsettings[appsettings.environment].sendToEmail,
                        message: {
                          subject:
                            "Name: " + email.name + " Email: " + email.email,
                          text: email.message
                        }
                      });
                      setEmail({ name: "", email: "", message: "" });
                    }}
                  >
                    Send Message
                  </Button>
                </GridItem>
              </GridContainer>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    </HeaderFooter>
  );
}

const customeStyles = {
  campuse: {
    fontWeight: "bold",
    color: "#3CB371",
    display: "flex",
    justifyContent: "center"
  }
};
