/* eslint-disable react/prop-types */
import React, { useContext } from "react";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import WhatsAppFloatingButton from "components/WhatsAppFloatingBtn";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import coursesStyle from "assets/jss/material-kit-react/views/landingPageSections/coursesStyle.js";
import Button from "components/CustomButtons/Button.js";

import { CurrentUserContext } from "root/Store.js";
import RegistartionForm from "./Registration/Registration.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);
const useCoursesStyles = makeStyles(coursesStyle);

export default function HeaderFooter(props) {
  const [currentUser] = useContext(CurrentUserContext);
  const [isApplicationFormVisible, setApplcationFormVisible] = React.useState(
    false
  );
  const classes = useStyles();
  const coursesClasses = useCoursesStyles();
  const { ...rest } = props;
  return (
    <div style={{ backgroundColor: "white" }}>
      <Header
        /*color="transparent"*/
        fixed
        isHomeScreen
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks {...props} isHomeScreen />}
        isApplicationFormVisible={isApplicationFormVisible}
        changeColorOnScroll={{
          height: 20,
          color: "white"
        }}
        {...rest}
      />
      {props.children}
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div className={coursesClasses.section}>
            <RegistartionForm
              {...props}
              isVisible={isApplicationFormVisible}
              setVisible={setApplcationFormVisible}
            />
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8}>
                <Button
                  color="success"
                  size="lg"
                  onClick={() => {
                    if (currentUser) {
                      setApplcationFormVisible(true);
                      // eslint-disable-next-line react/prop-types
                    } else props.history.push("/login");
                  }}
                >
                  <i className="fas fa-bars" />
                  Register Online
                </Button>
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <h2 className={coursesClasses.title}>
                  Fezile Fashion Skills Academy
                </h2>
                <h5 className={coursesClasses.description}>
                  Fezile Fashion Skills Academy (FFSA) is a young vibrant
                  fashion hub for aspiring creatives. Established by former
                  Durban University of Technology Lecturer with over 15 years of
                  industry experience, the academy prides itself with providing
                  quality education. The academy provides accredited training
                  services and the development of programmes for Design,
                  Clothing Manufacturing and Entrepreneurship. With an
                  electrifying passion, the team brings a fresh and contemporary
                  feel for fashion enthusiasts alike. To lead, maintain being
                  relevant and trendy is the name of our game. FFSA is also
                  affiliated and embraces multiple channels in the fashion
                  industry that include fashion weeks, fashion television shows,
                  student competition programmes just to name a few. We are all
                  geared up to educate, while providing ideal avenues to make
                  your career in fashion a huge success.
                </h5>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <WhatsAppFloatingButton />
      <Footer />
    </div>
  );
}
