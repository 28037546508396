import * as api from "api/emailApi";
import * as documentPicker from "shared/utils/documentPicker";

export const uploadDocument = async (users, documentsParentId, dispatch) => {
  await documentPicker.pick(documentsParentId, dispatch);
  api.sendMail({
    bcc: users.map(u => u.email),
    message: {
      subject: "FFSA Course Announcement",
      text:
        "Hi Students \n\n New material has been uploaded. \n\n Regards \n FFSA Team"
    }
  });
};
