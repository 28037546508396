import React, { useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import fezile_mdletshe from "assets/img/faces/fezile_mdletshe2.jpg";
import hlengiwe_gumede from "assets/img/faces/hlengiwe_gumede.jpg";
import ayanda_cele from "assets/img/faces/ayanda_cele.jpeg";
import nokukhanya_chili from "assets/img/faces/nokukhanya_chili.jpeg";
import nonkululeko_dlamini from "assets/img/faces/nonkululeko_dlamini.JPG";
import amanda_mbali from "assets/img/faces/amanda_mbali.jpeg";
import thandanani_majola from "assets/img/faces/thandanani_majola.jpeg";

import FezileFullBio from "./FezileFullBio.js";
import HeaderFooter from "../HeaderFooter.js";

const useStyles = makeStyles(styles);

export default function Team(props) {
  const [isFezileBioVisible, setFezileBioVisible] = useState(false);
  const [isHlengiweBioVisible, setHlengiweBioVisible] = useState(false);
  const [isAyandaBioVisible, setAyandaBioVisible] = useState(false);
  const [isAmandaBioVisible, setAmandaBioVisible] = useState(false);
  const [isNokukhanyaBioVisible, setNokukhanyaBioVisible] = useState(false);
  const [isNonkululekoBioVisible, setNonkululekoBioVisible] = useState(false);
  const [
    isThandananiMajolaBioVisible,
    setThandananiMajolaBioVisible
  ] = useState(false);

  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <HeaderFooter {...props}>
      <div id="team" className={classes.section}>
        <h2 className={classes.title}>TEAM</h2>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4} />
            <GridItem xs={12} sm={12} md={4}>
              <div
                onMouseEnter={() => setFezileBioVisible(true)}
                onClick={() => setFezileBioVisible(true)}
                onMouseLeave={() => setFezileBioVisible(false)}
              >
                <Card plain>
                  <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                    <img
                      src={fezile_mdletshe}
                      alt="..."
                      className={imageClasses}
                    />
                  </GridItem>
                  <h4 className={classes.cardTitle}>
                    Fezile Mdletshe
                    <br />
                    <small className={classes.smallTitle}>
                      Chief Executive Officer
                    </small>
                  </h4>
                  <CardBody>
                    <p
                      className={classNames(
                        "description",
                        `${isFezileBioVisible ? "show" : "hide"}`
                      )}
                    >
                      Fezile, who is currently a PhD candidate under the DUT
                      Doctoral Mentorship Programme holds a Master of Applied
                      Arts-Fashion Degree (DUT), Bachelor of Technology Degree
                      in Fashion (DUT) and a National Diploma in Fashion (DUT)
                      {isFezileBioVisible && <FezileFullBio />}
                    </p>
                  </CardBody>
                  {isFezileBioVisible && (
                    <CardFooter className={classes.justifyCenter}>
                      <Button
                        justIcon
                        color="transparent"
                        target="_blank"
                        href="https://www.instagram.com/fezile_fashion/"
                        className={classes.margin5}
                      >
                        <i className={classes.socials + " fab fa-instagram"} />
                      </Button>
                      <Button
                        justIcon
                        color="transparent"
                        target="_blank"
                        href="https://www.facebook.com/profile.php?id=100009673720231"
                        className={classes.margin5}
                      >
                        <i className={classes.socials + " fab fa-facebook"} />
                      </Button>
                      <Button
                        justIcon
                        color="transparent"
                        target="_blank"
                        href="https://za.linkedin.com/in/fezile-l-mdletshe-9b66405b"
                        className={classes.margin5}
                      >
                        <i className={classes.socials + " fab fa-linkedin"} />
                      </Button>
                      <Button
                        justIcon
                        color="transparent"
                        target="_blank"
                        href="https://twitter.com/fezile_fashion"
                        className={classes.margin5}
                      >
                        <i className={classes.socials + " fab fa-twitter"} />
                      </Button>
                    </CardFooter>
                  )}
                </Card>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} />
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <div
                onMouseEnter={() => setAyandaBioVisible(true)}
                onClick={() => setAyandaBioVisible(true)}
                onMouseLeave={() => setAyandaBioVisible(false)}
              >
                <Card plain>
                  <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                    <img src={ayanda_cele} alt="..." className={imageClasses} />
                  </GridItem>
                  <h4 className={classes.cardTitle}>
                    Ayanda Cele
                    <br />
                    <small className={classes.smallTitle}>
                      Head of Administration
                    </small>
                  </h4>
                  <CardBody>
                    <p
                      className={classNames(
                        "description",
                        `${isAyandaBioVisible ? "show" : "hide"}`
                      )}
                    >
                      Ayanda has a background in Information Technology and
                      Office Administration. She does the most to ensure
                      efficiency at the Academy. She is the lady who swiftly
                      responds to all your inquiries regarding the academy and
                      its course offering.
                    </p>
                  </CardBody>
                  <CardFooter className={classes.justifyCenter}></CardFooter>
                </Card>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <div
                onMouseEnter={() => setHlengiweBioVisible(true)}
                onClick={() => setHlengiweBioVisible(true)}
                onMouseLeave={() => setHlengiweBioVisible(false)}
              >
                <Card plain>
                  <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                    <img
                      src={hlengiwe_gumede}
                      alt="..."
                      className={imageClasses}
                    />
                  </GridItem>
                  <h4 className={classes.cardTitle}>
                    Hlengiwe Gumede
                    <br />
                    <small className={classes.smallTitle}>
                      Head of Skills Development, Teaching and Learning
                    </small>
                  </h4>
                  <CardBody>
                    <p
                      className={classNames(
                        "description",
                        `${isHlengiweBioVisible ? "show" : "hide"}`
                      )}
                    >
                      Hlengiwe is a young energetic soul from Inanda. She has an
                      amazing love for working with local community in training
                      and skills development. She is a DUT graduate who achieved
                      a GIDEON MERIT award at 2018 DUT fashion show (art of
                      fashion) and has showcased at the 2019 SOUTH AFRICAN
                      FASHION WEEK.
                    </p>
                  </CardBody>
                </Card>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <div
                onMouseEnter={() => setAmandaBioVisible(true)}
                onClick={() => setAmandaBioVisible(true)}
                onMouseLeave={() => setAmandaBioVisible(false)}
              >
                <Card plain>
                  <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                    <img
                      src={amanda_mbali}
                      alt="..."
                      className={imageClasses}
                    />
                  </GridItem>
                  <h4 className={classes.cardTitle}>
                    Amanda Mbali
                    <br />
                    <small className={classes.smallTitle}>
                      Head of Strategy and Planning
                    </small>
                  </h4>
                  <CardBody>
                    <p
                      className={classNames(
                        "description",
                        `${isAmandaBioVisible ? "show" : "hide"}`
                      )}
                    >
                      With a great passion for people, organising and
                      communicating, Amanda Mbali is an extrovert young woman
                      best known for her smile and warm aura. She is a DUT
                      graduate with a Btech in Public Relations Management. Her
                      experience in different industries and ability to work
                      with people from different avenues of life adds a dash of
                      charisma to her personality.
                    </p>
                  </CardBody>
                  <CardFooter className={classes.justifyCenter}></CardFooter>
                </Card>
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <div
                onMouseEnter={() => setNokukhanyaBioVisible(true)}
                onClick={() => setNokukhanyaBioVisible(true)}
                onMouseLeave={() => setNokukhanyaBioVisible(false)}
              >
                <Card plain>
                  <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                    <img
                      src={nokukhanya_chili}
                      alt="..."
                      className={imageClasses}
                    />
                  </GridItem>
                  <h4 className={classes.cardTitle}>
                    Nokukhanya Chili
                    <br />
                    <small className={classes.smallTitle}>Receptionist</small>
                  </h4>
                  <CardBody>
                    <p
                      className={classNames(
                        "description",
                        `${isNokukhanyaBioVisible ? "show" : "hide"}`
                      )}
                    >
                      Khanyo is a young lady who started at the Academy as an
                      intern. For her hardworking and vibrant nature she is now
                      handling the marketing and PR portfolio at FFSA. She is
                      known for her outgoing personality and infectious laugh.
                    </p>
                  </CardBody>
                  <CardFooter className={classes.justifyCenter}></CardFooter>
                </Card>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <div
                onMouseEnter={() => setThandananiMajolaBioVisible(true)}
                onClick={() => setThandananiMajolaBioVisible(true)}
                onMouseLeave={() => setThandananiMajolaBioVisible(false)}
              >
                <Card plain>
                  <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                    <img
                      src={thandanani_majola}
                      alt="..."
                      className={imageClasses}
                    />
                  </GridItem>
                  <h4 className={classes.cardTitle}>
                    Thandanani Majola
                    <br />
                    <small className={classes.smallTitle}>Mentor</small>
                  </h4>
                  <CardBody>
                    <p
                      className={classNames(
                        "description",
                        `${isThandananiMajolaBioVisible ? "show" : "hide"}`
                      )}
                    >
                      Thandanani Majola was born and raised in Tongaat. He is a
                      DUT Fashion Graduate who is also a part time model.
                      Thandanani is an expert in Garment Construction and
                      Pattern Technology..
                    </p>
                  </CardBody>
                  <CardFooter className={classes.justifyCenter}></CardFooter>
                </Card>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <div
                onMouseEnter={() => setNonkululekoBioVisible(true)}
                onClick={() => setNonkululekoBioVisible(true)}
                onMouseLeave={() => setNonkululekoBioVisible(false)}
              >
                <Card plain>
                  <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                    <img
                      src={nonkululeko_dlamini}
                      alt="..."
                      className={imageClasses}
                    />
                  </GridItem>
                  <h4 className={classes.cardTitle}>
                    Nonkululeko Dlamini
                    <br />
                    <small className={classes.smallTitle}>
                      Office Assistant & Technical Assistant
                    </small>
                  </h4>
                  <CardBody>
                    <p
                      className={classNames(
                        "description",
                        `${isNonkululekoBioVisible ? "show" : "hide"}`
                      )}
                    >
                      Sisi Nonku keeps the train moving at FFSA by ensuring that
                      our premises are tidy, and all labs are in order in time
                      to classes. She is currently training to be Technical
                      Assistant at FFSA.
                    </p>
                  </CardBody>
                  <CardFooter className={classes.justifyCenter}></CardFooter>
                </Card>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </HeaderFooter>
  );
}
