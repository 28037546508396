import React, { useContext } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/dashboard/admins/staffAdd.js";
import placeholder from "assets/img/faces/user.png";

import * as api from "api/index.js";
import {
  UsersContext,
  StudentCourseContext
} from "views/Dashboard/root/Store.js";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function StudentAdd(props) {
  const [users] = useContext(UsersContext);
  const [studentCourse] = useContext(StudentCourseContext);
  const classes = useStyles();
  // eslint-disable-next-line react/prop-types
  const { isVisible, setVisible, courseId } = props;

  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );

  const data = users.data.filter(
    u =>
      !u.isAdmin &&
      u.title == "Student" &&
      !studentCourse.data.some(
        sc => sc.courseId == courseId && sc.studentId == u.id
      )
  );
  const addStudent = student => {
    api.setData("studentCourse", {
      studentId: student.id,
      courseId: courseId
    });
    setVisible(false);
  };
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Dialog
              classes={{
                root: classes.center,
                paper: classes.modal
              }}
              open={isVisible}
              style={{ minWidth: "100px" }}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => setVisible(false)}
              aria-labelledby="classic-modal-slide-title"
              aria-describedby="classic-modal-slide-description"
            >
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <IconButton
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={() => setVisible(false)}
                >
                  <Close className={classes.modalClose} />
                </IconButton>
                <h4 align={"center"} className={classes.modalTitle}>
                  Add Student
                </h4>
              </DialogTitle>
              <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
              >
                {data
                  .sort((a, b) => b.createdAt - a.createdAt)
                  .map((student, key) => (
                    <GridContainer
                      key={key}
                      style={{ marginBottom: "10px" }}
                      onClick={() => addStudent(student)}
                    >
                      <GridItem xs={4} sm={4} md={4} row>
                        <img
                          src={
                            student.avatar == "" ? placeholder : student.avatar
                          }
                          alt="..."
                          className={imageClasses}
                        />
                      </GridItem>
                      <GridItem
                        xs={8}
                        sm={8}
                        md={8}
                        style={{ paddingTop: "10px" }}
                      >
                        <strong>
                          {student.name} {student.surname}
                        </strong>
                        <br />
                        <span>{student.title} </span>
                      </GridItem>
                    </GridContainer>
                  ))}
              </DialogContent>
              <DialogActions className={classes.modalFooter}>
                <Button onClick={() => setVisible(false)} color="danger" simple>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
