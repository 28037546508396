import React, { useState } from "react";
import { isMobile } from "react-device-detect";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
import EmailOutlined from "@material-ui/icons/EmailOutlined";
import PersonOutline from "@material-ui/icons/PersonOutline";
import LocationCityOutlined from "@material-ui/icons/LocationCityOutlined";
import HomeOutlined from "@material-ui/icons/HomeOutlined";

// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-kit-react/views/loginPage.js";
import banner1 from "assets/img/banners/0.png";
import background from "assets/img/background-3.jpg";

import * as api from "api/authApi.js";
import * as validations from "shared/utils/validations.js";

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const [inProgress, setInProgress] = useState(false);
  const [user, setUser] = useState({ avatar: "", title: "Student" });

  const classes = useStyles();
  const { ...rest } = props;

  setTimeout(function() {
    setCardAnimation("");
  }, 700);

  return (
    <div>
      <Header
        absolute
        color="transparent"
        rightLinks={<HeaderLinks {...props} />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: isMobile
            ? "url(" + background + ")"
            : "url(" + banner1 + ")",
          backgroundSize: "contain",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={5}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <p className={classes.divider}>
                    <h4>Create Account</h4>
                  </p>
                  <CardBody>
                    <CustomInput
                      labelText="First Name..."
                      id="first"
                      value={user.name}
                      onChangeValue={value => setUser({ ...user, name: value })}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        endAdornment: (
                          <InputAdornment position="end">
                            <PersonOutline
                              className={classes.inputIconsColor}
                            />
                          </InputAdornment>
                        )
                      }}
                    />
                    <CustomInput
                      labelText="Last Name..."
                      id="last"
                      value={user.surname}
                      onChangeValue={value =>
                        setUser({ ...user, surname: value })
                      }
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        endAdornment: (
                          <InputAdornment position="end">
                            <PersonOutline
                              className={classes.inputIconsColor}
                            />
                          </InputAdornment>
                        )
                      }}
                    />
                    <CustomInput
                      labelText="City"
                      id="city"
                      value={user.city}
                      onChangeValue={value => setUser({ ...user, city: value })}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        endAdornment: (
                          <InputAdornment position="end">
                            <LocationCityOutlined
                              className={classes.inputIconsColor}
                            />
                          </InputAdornment>
                        )
                      }}
                    />
                    <CustomInput
                      labelText="Suburb/township..."
                      id="Suburb/township"
                      value={user.suburb_township}
                      onChangeValue={value =>
                        setUser({ ...user, suburb_township: value })
                      }
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        endAdornment: (
                          <InputAdornment position="end">
                            <HomeOutlined className={classes.inputIconsColor} />
                          </InputAdornment>
                        )
                      }}
                    />
                    <CustomInput
                      labelText="Email..."
                      id="email"
                      value={user.email}
                      onChangeValue={value =>
                        setUser({ ...user, email: value })
                      }
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "email",
                        endAdornment: (
                          <InputAdornment position="end">
                            <EmailOutlined
                              className={classes.inputIconsColor}
                            />
                          </InputAdornment>
                        )
                      }}
                    />
                    <CustomInput
                      labelText="Password"
                      id="password"
                      value={user.password}
                      onChangeValue={value =>
                        setUser({ ...user, password: value })
                      }
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "password",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off"
                      }}
                    />
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    {!inProgress ? (
                      <Button
                        simple
                        color="primary"
                        size="lg"
                        disabled={inProgress}
                        onClick={() => {
                          if (validations.signup(user)) {
                            setInProgress(true);
                            api.signup(user, setInProgress, props);
                          }
                        }}
                      >
                        Submit
                      </Button>
                    ) : (
                      <CircularProgress />
                    )}
                  </CardFooter>
                  <CardFooter className={classes.cardFooter}>
                    <a
                      href="#"
                      onClick={() => {
                        // eslint-disable-next-line react/prop-types
                        props.history.push("/login");
                      }}
                    >
                      Login
                    </a>
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
