import { toast } from "react-toastify";

// eslint-disable-next-line no-useless-escape
const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const signup = user => {
  if (!user.name || user.name === "") {
    toast.error("Enter name.");
    return false;
  }
  if (!user.city || user.city == "") {
    toast.error("Enter city.");
    return false;
  }
  if (!user.email || user.email == "") {
    toast.error("Enter email.");
    return false;
  }
  if (!user.email.match(mailFormat)) {
    toast.error("You have entered an invalid email address!");
    return false;
  }
  if (!user.password || user.password == "") {
    toast.error("Enter password.");
    return false;
  }

  return true;
};

export const login = user => {
  if (!user.email || user.email == "") {
    toast.error("Enter email.");
    return false;
  }
  if (!user.email.match(mailFormat)) {
    toast.error("You have entered an invalid email address!");
    return false;
  }
  if (!user.password || user.password == "") {
    toast.error("Enter password.");
    return false;
  }

  return true;
};

export const validate = (obj, fields) => {
  let result = true;

  fields.map(field => {
    if (!obj[field] || obj[field] == "") {
      toast.error("Enter " + field);
      result = false;
      fields.length = 0;
    }
  });

  return result;
};
