import firebase from "firebase";
import { toast } from "react-toastify";
import moment from "moment";

import appsettings from "appsettings.json";

if (!firebase.apps.length)
  firebase.initializeApp(appsettings[appsettings.environment].firebaseConfig);

const db = firebase.firestore();

export const sendMail = email =>
  db
    .collection("mail")
    .doc(moment(new Date()).format("YYYY MMM DD HH:mm:ss.SSSSSS"))
    .set(email)
    .then(() => toast.success(email.message.subject));
