import React, { useContext } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import useStyles from "../../shared/utils/use-styles";
import FormControlSelect from "../../shared/components/dimensional-form-control-select/1D-form-control-select";
import CustomTextField from "../../shared/components/dimensional-text-fields/2D-text-field";

import { MarketingMediaOptionsContext } from "../../root/Store.js";

// eslint-disable-next-line react/prop-types
const Marketing = ({ marketingDetails, setMarketingDetails }) => {
  const [marketingMediaOptions] = useContext(MarketingMediaOptionsContext);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Paper className={classes.paper}>
          How did you hear about the programme you are applying for at FEZILE
          FASHION SKILLS ACADEMY?
        </Paper>
        <Grid item xs={12} sm={12} md={6}>
          <Paper className={classes.paper}>
            <FormControlSelect
              field={"id"}
              fieldName={"marketingMedia"}
              label={"Marketing Media"}
              index={true}
              options={[""].concat(
                marketingMediaOptions.data.map(mmo => mmo.Description)
              )}
              classes={classes}
              objectDetails={marketingDetails}
              setObjectDetails={setMarketingDetails}
            />

            {// eslint-disable-next-line react/prop-types
            marketingDetails.marketingMedia == "Guidance Consellor" && (
              <>
                <CustomTextField
                  type="text"
                  field1={"marketingMediaGuidanceConsellor"}
                  field2={"name"}
                  placeholder={"Guidance Consellor Name"}
                  helperText={"Please provide Name of Guidance Consellor."}
                  objectDetails={marketingDetails}
                  setObjectDetails={setMarketingDetails}
                />
                <CustomTextField
                  type="text"
                  field1={"marketingMediaGuidanceConsellor"}
                  field2={"contact"}
                  placeholder={"Guidance Consellor Contact"}
                  helperText={"Please provide Contact of Guidance Consellor."}
                  objectDetails={marketingDetails}
                  setObjectDetails={setMarketingDetails}
                />
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Marketing;
