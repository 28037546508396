/* eslint-disable react/prop-types */
import React, { useContext } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-kit-react/views/dashboard/applications/payments.js";

import { CurrentUserContext } from "root/Store.js";
import { FeesContext, PaymentsContext } from "views/Dashboard/root/Store.js";
import appsettings from "appsettings.json";
import Receipt from './Receipt'


const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function Payments(props) {
  const [fees] = useContext(FeesContext);
  const [payments] = useContext(PaymentsContext);
  const [currentUser] = useContext(CurrentUserContext);
  const classes = useStyles();
  const { isVisible, setVisible, application } = props;

  const userPayments = payments.data.filter(
    p => p.custom_str1 == application.applicantID
  );

  const data = fees.data.filter(fee => fee.courseId == application.courseId);

  const pay = {
    merchant_id: appsettings[appsettings.environment].payfast.merchant_id,
    merchant_key: appsettings[appsettings.environment].payfast.merchant_key,
    notify_url: appsettings[appsettings.environment].payfast.notify_url,
    baseUrl: appsettings[appsettings.environment].payfast.base_url,
    return_url: appsettings[appsettings.environment].payfast.return_url,
    cancel_url: appsettings[appsettings.environment].payfast.return_url
  };

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Dialog
              classes={{
                root: classes.center,
                paper: classes.modal
              }}
              open={isVisible}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => setVisible(false)}
              aria-labelledby="classic-modal-slide-title"
              aria-describedby="classic-modal-slide-description"
            >
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <IconButton
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={() => setVisible(false)}
                >
                  <Close className={classes.modalClose} />
                </IconButton>
                <h4 align={"center"} className={classes.modalTitle}>
                  {application.course.name + " FEES"}
                </h4>
              </DialogTitle>
              <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
              >
                {data
                  .sort((a, b) => b.createdAt - a.createdAt)
                  .map((fee, key) => (
                    <GridContainer
                      style={{ justifyContent: "center" }}
                      key={key}
                    >
                      <GridItem xs={4} sm={4} md={4} lg={4}>
                        <div style={{ marginTop: "30px" }}>{fee.name}</div>
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4} lg={4}>
                        <CustomInput
                          value={"R" + fee.amount}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={2} sm={2} md={2} lg={2}>
                        <div id="btn" className={classes.title}>
                          <Button
                            disabled={userPayments.some(up => up.custom_str2 == fee.id)}
                            onClick={() => {
                              window.open(
                                `${pay.baseUrl}?merchant_id=${pay.merchant_id}&merchant_key=${pay.merchant_key}&item_name=${fee.name}&item_description=${application.course.name}&amount=${fee.amount}&return_url=${pay.return_url}&cancel_url=${pay.cancel_url}&custom_str1=${currentUser.id}&custom_str2=${fee.id}&custom_str3=${application.courseId}&custom_str4=${application.id}&notify_url=${pay.notify_url}`,
                                "_blank"
                              );
                            }}
                            color={
                              userPayments.some(up => up.custom_str2 == fee.id)
                                ? "success"
                                : "info"
                            }
                          >
                            {userPayments.some(up => up.custom_str2 == fee.id)
                              ? "Paid"
                              : "Pay"}
                          </Button>
                        </div>
                      </GridItem>
                       <Receipt fee={fee} application={application} />
                    </GridContainer>
                  ))}
                {data.length == 0 && (
                  <h6 align={"center"} style={{ color: "red" }}>
                    No fees added for this course.
                  </h6>
                )}
              </DialogContent>
              <DialogActions className={classes.modalFooter}>
                <Button onClick={() => setVisible(false)} color="danger" simple>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}