// import "assets/scss/material-kit-react.scss?v=1.8.0";
// import "@patternfly/react-core/dist/styles/base.css";
// import "react-toastify/dist/ReactToastify.css";
//import "./index.css";

import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Components from "views/Components/Components.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import SignupPage from "views/SignupPage/SignupPage.js";
import Dashboard from "views/Dashboard/Dashboard.js";
import Admins from "views/Dashboard/Sections/Admins/Admins.js";
import Courses from "views/Dashboard/Sections/Courses/List.js";
import CourseView from "views/Dashboard/Sections/Courses/View/View.js";
import Applications from "views/Dashboard/Sections/Applications/List.js";
import Students from "views/Dashboard/Sections/Students/Students.js";
import Campuses from "views/LandingPage/Sections/Campuses";
import CoursesOffered from "views/LandingPage/Sections/Courses";
import StudentLife from "views/LandingPage/Sections/StudentLife";
import Team from "views/LandingPage/Sections/Team";
import Affiliations from "views/LandingPage/Sections/Affiliations";
import Contact from "views/LandingPage/Sections/Contact";

import Store from "root/Store.js";

var hist = createBrowserHistory();

ReactDOM.render(
  <Store>
    <Router history={hist}>
      <Switch>
        <Route path="/" exact component={LandingPage} />
        <Route path="/login" component={LoginPage} />
        <Route path="/signup" component={SignupPage} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/admins" component={Admins} />
        <Route path="/courses" component={Courses} />
        <Route path="/course/:id" component={CourseView} />
        <Route path="/applications" component={Applications} />
        <Route path="/students" component={Students} />
        <Route path="/campuses" component={Campuses} />
        <Route path="/courses-offered" component={CoursesOffered} />
        <Route path="/student-life" component={StudentLife} />
        <Route path="/team" component={Team} />
        <Route path="/affiliations" component={Affiliations} />
        <Route path="/contact" component={Contact} />
        <Route path="/components" component={Components} />
        <Redirect from="*" to="/" />
      </Switch>
      <ToastContainer />
    </Router>
  </Store>,
  document.getElementById("root")
);
