/* eslint-disable react/prop-types */
import React, { useContext, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Clearfix from "components/Clearfix/Clearfix.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/notificationsStyles.js";
import { FoldersContext } from "views/Dashboard/Sections/Courses/root/Store.js";

const useStyles = makeStyles(styles);

export default function List(props) {
  const [folders] = useContext(FoldersContext);
  const [search, setSearch] = useState("");
  const { navigation, setNavigation, setSection } = props;
  const classes = useStyles();

  const data = folders.data.filter(
    f =>
      f.parentId == props.navigation.courseId &&
      f.section != "videos" &&
      f.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div
      className={classes.section}
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
      }}
    >
      <CustomInput
        labelText="Search..."
        id="Search"
        value={search}
        onChangeValue={value => setSearch(value)}
        formControlProps={{
          fullWidth: true
        }}
      />
      {data
        .sort((a, b) => b.createdAt - a.createdAt)
        .map((folder, key) => (
          <span
            key={key}
            onClick={() => {
              setNavigation({ ...navigation, folderId: folder.id });
              setSection("subfolders");
            }}
          >
            <SnackbarContent
              key={key}
              message={
                <span style={{ color: "black", maxWidth: "100px" }}>
                  <b>{folder.name}</b>
                </span>
              }
              color="black"
              icon="folder_outline"
            />
          </span>
        ))}
      <div center style={{ marginTop: 10 }}>
        {data.length == 0 && "No folders."}
      </div>
      <Clearfix />
    </div>
  );
}
