/* eslint-disable react/prop-types */
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";

// core components
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-kit-react/views/dashboard/admins/staffPage.js";

import { DocumentsContext } from "views/Dashboard/root/Store.js";
import { CurrentUserContext } from "root/Store.js";
import EditDocumentModal from "./DocumentSecurity.js";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function Documents(props) {
  const [documents] = useContext(DocumentsContext);
  const [currentUser] = useContext(CurrentUserContext);
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [EditDocumentVisible, setEditDocumentVisible] = useState(false);
  const [EditDocument, setEditDocument] = useState();

  const data = documents.data.filter(
    doc =>
      doc.parentId == props.navigation.subFolderId &&
      doc.name.toLowerCase().includes(search.toLowerCase())
  );

  const openDocument = doc => {
    setEditDocument(doc);
    setEditDocumentVisible(true);
  };

  return (
    <div className={classes.section} id="notifications">
      <div className={classes.container} style={{ maxHeight: "500px" }}>
        <span style={{ display: "flex" }}>
          <CustomInput
            labelText="Search..."
            id="Search"
            value={search}
            onChangeValue={value => setSearch(value)}
            formControlProps={{
              fullWidth: true
            }}
          />
          <ArrowBackIos
            onClick={() => {
              props.setSection("subfolders");
            }}
            style={{ marginTop: "30px" }}
          />
        </span>
        {data
          .sort((a, b) => b.createdAt - a.createdAt)
          .map((doc, key) => (
            <span
              onClick={() =>
                doc.isLocked
                  ? toast.info("This document is locked!")
                  : window.open(doc.url, "_blank")
              }
              key={key}
            >
              <SnackbarContent
                iconClick={event => {
                  event.stopPropagation();
                  currentUser.isAdmin && openDocument(doc);
                }}
                message={
                  <span style={{ color: "black" }}>
                    <b>{doc.name}</b>
                  </span>
                }
                color="black"
                icon={doc.isLocked ? "lock_outline" : "picture_as_pdf_outline"}
              />
            </span>
          ))}
        <div center>{data.length == 0 && "No documents."}</div>
        <EditDocumentModal
          doc={EditDocument}
          isVisible={EditDocumentVisible}
          setVisible={setEditDocumentVisible}
        />
      </div>
    </div>
  );
}
