/* eslint-disable react/prop-types */
import "./List.css";

import moment from "moment";
import React, { useContext, useState, useEffect } from "react";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
import Book from "@material-ui/icons/LibraryBooks";
import Autorenew from "@material-ui/icons/Autorenew";
import PaymentOutlined from "@material-ui/icons/PaymentOutlined";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import WhatsAppFloatingButton from "components/WhatsAppFloatingBtn";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { CurrentUserContext } from "root/Store.js";
import { ApplicationsContext } from "views/Dashboard/root/Store.js";

import EditStatus from "./EditStatusModal.js";
import Payments from "./Payments.js";
import colors from "shared/constants/colors.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function List(props) {
  const [applications] = useContext(ApplicationsContext);
  const [currentUser] = useContext(CurrentUserContext);
  const [application, setApplication] = useState({});
  const classes = useStyles();
  const { ...rest } = props;

  const [isAddVisible, setEditVisible] = useState(false);
  const [isPaymentsVisible, setPaymentsVisible] = useState(false);

  const editStatus = applicationData => {
    setApplication({
      ...applicationData,
      oldStatus: applicationData.status
    });
    setEditVisible(true);
  };
  const payments = applicationData => {
    setApplication(applicationData);
    setPaymentsVisible(true);
  };

  useEffect(() => {
    if (!currentUser) props.history.push("/login");
  }, []);

  const data =
    currentUser && currentUser.isAdmin
      ? applications.data.filter(a => a.applicationDetailsSent)
      : applications.data.filter(a => a.applicantID == currentUser.id);

  return (
    <div>
      <Header
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks {...props} />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "white"
        }}
        {...rest}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          {isAddVisible && (
            <EditStatus
              statusBefore={application.oldStatus}
              application={application}
              setApplication={setApplication}
              isVisible={isAddVisible}
              setVisible={setEditVisible}
            />
          )}
          {isPaymentsVisible && (
            <Payments
              application={application}
              isVisible={isPaymentsVisible}
              setVisible={setPaymentsVisible}
            />
          )}
          <GridContainer
            style={{
              height: "700px",
              paddingTop: "50px",
              overflow: "auto",
              color: "#56b383"
            }}
            justify={"center"}
          >
            {data.length == 0 &&
              !applications.inProgress &&
              "No pending applications!"}
            {applications.inProgress && (
              <CircularProgress style={{ margin: "50px" }} />
            )}
            {data
              .sort((a, b) => b.createdAt - a.createdAt)
              .map((application, i) => (
                <GridItem
                  key={i}
                  xs={12}
                  sm={12}
                  md={8}
                  onClick={() => currentUser.isAdmin && editStatus(application)}
                >
                  <Card>
                    <CardHeader
                      style={{
                        ..._styles.cardHeader,
                        background: colors[application.color]
                      }}
                      stats
                      icon
                    >
                      <CardIcon
                        id="cardicon"
                        style={{
                          ..._styles.cardIcon,
                          background: colors[application.color]
                        }}
                      >
                        <Book />
                      </CardIcon>
                      {application.course.name}
                    </CardHeader>
                    <CardFooter
                      stats
                      style={{ justifyContent: "space-between" }}
                    >
                      <div className={classes.stats} style={{}}>
                        <Autorenew />
                        {application.status}
                      </div>
                      <span
                        onClick={event => {
                          event.stopPropagation();
                          payments({
                            ...application,
                            courseId: application.course.id,
                            courseName: application.course.name
                          });
                        }}
                        style={{ display: "flex" }}
                      >
                        <PaymentOutlined style={{ color: "green" }} />
                        <p style={{ marginTop: "3px" }}>Payments</p>
                      </span>
                      <span>
                        {application.studentDetails.firstNames}{" "}
                        {application.studentDetails.surname}
                      </span>
                      <span>
                        {moment(application.createdAt).format(
                          "DD MMM YYYY HH:mm"
                        )}
                      </span>
                    </CardFooter>
                  </Card>
                </GridItem>
              ))}
          </GridContainer>
          <GridContainer style={{ height: "50px" }} />
        </div>
      </div>
      <WhatsAppFloatingButton />
      <Footer />
    </div>
  );
}

const _styles = {
  cardHeader: {
    textAlign: "center",
    boxShadow: "2px 2px 5px 0px rgba(0,0,0,0.5)",
    color: "#fff"
  },
  cardIcon: {
    padding: "15px",
    marginTop: "-20px",
    marginRight: "15px",
    borderRadius: "3px",
    backgroundColor: "#999",
    float: "left",
    boxShadow: "2px 2px 5px 0px rgba(0,0,0,0.5)",
    color: "#fff"
  }
};
