import "./index.css";

import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import GridContainer from "components/Grid/GridContainer.js";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";

import FormControlLabel from "@material-ui/core/FormControlLabel";

import GoogleMaps from "./google-maps.js";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  }
}));

// eslint-disable-next-line react/prop-types
const CustomControlLabel = ({ icon, label }) => (
  <FormControlLabel
    className={"formControlLabel"}
    control={<Icon className={icon} color="primary" />}
    label={label}
  />
);

const LocationDetails = () => {
  const classes = useStyles();

  return (
    <GridContainer justify="center" className={classes.section}>
      <Grid item xs={11} sm={3} md={3}>
        <CustomControlLabel
          icon={"fa fa-envelope"}
          label={"admin@ffsaza.co.za / ayanda@ffsaza.co.za"}
        />
        <CustomControlLabel
          icon={"fa fa-phone"}
          label={
            <span>
              {"Durban branch: 031-100-0141 "}
              <br />
              {"Cell& What’s app: 0670658662"}
            </span>
          }
        />
        <CustomControlLabel
          icon={"fa fa-home"}
          label={"Durban branch: 369/373 Umgeni Road Greyville Durban 4001"}
        />
      </Grid>
      <Grid item xs={12} sm={7} md={7}>
        <Paper className={classes.paper}>
          <GoogleMaps />
        </Paper>
      </Grid>
    </GridContainer>
  );
};
export default LocationDetails;
