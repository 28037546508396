import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/javascriptStyles.js";

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function FezileFullBio() {
  const [classicModal, setClassicModal] = React.useState(false);
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <Button color="transparent" onClick={() => setClassicModal(true)}>
          show more...
        </Button>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={classicModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setClassicModal(false)}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <IconButton
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => setClassicModal(false)}
            >
              <Close className={classes.modalClose} />
            </IconButton>
            <h4 className={classes.modalTitle}>Bio</h4>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            <p>
              Fezile Mdletshe is the Executive Director and Founder of the
              Fezile Fashion Skills Academy and the Fezile Mdletshe Fashion
              Agency.{" "}
            </p>
            <br />
            <p>
              She has recently completed her first year under the DUT PhD
              Mentorship Programme. She will continue her studies until she
              graduates as a Doctor in Fashion Studies in April 2022. Fezile
              holds a Master of Applied Arts-Fashion Degree (DUT), a Bachelor of
              Technology Degree in Fashion (DUT) and a National Diploma in
              Fashion (DUT). A graduate and Former Fashion Design Lecturer at
              DUT’s Department of Fashion & Textiles, she brings her passion and
              a great love for fashion coupled with an extensive and impressive
              history in the industry to any intense learning environment.{" "}
            </p>
            <br />
            <p>
              {" "}
              Having worked with some of South Africa’s biggest fashion retail
              chains- Mr Price & Edcon and having previously mentored emerging
              young fashion designers through the KZN Fashion Council she is in
              a unique position to educate and guide when it comes to industry,
              trends and brand development. Fezile was also on Season 1 of the
              Fashion Reality Show called Made in Africa as a Mentor and Judge
              which premiered on BET Chanel 129 in 2018. In 2019, Fezile was
              dubbed by H & M international and the Sunday Times as one of
              Africa’s Rising Stars for her contribution towards her local
              community by opening the first accredited Township based Fashion
              Design School. Alongside her dedicated team, Fezile has launched
              one of the fastest growing fashion agencies & academy in the
              province.{" "}
            </p>
            <br />
            <p>
              {" "}
              In year 2018 Fezile Mdletshe Fashion Agency (PTY) Ltd was
              appointed to manage, conceptualize and coordinate various fashion
              development projects across the province and also internationally
              such as the Harry Gwala Summer Cup 2018, Drakensberg Extravaganza
              and Lagos Fashion Week 2018. From these projects alongside
              Fezile’s many years of fashion coaching through the KZN Fashion
              Council, she has identified a great need for further development
              mainly within the fashion space of clothing and textiles. Her
              entrepreneurial flair was demonstrated in initiating and running a
              successful online shopping company from 2012 to 2014. Her
              professional and academic background gives her the necessary
              skills lead her companies.
            </p>{" "}
            <br />
            <p>
              Fezile is very passionate about the development of untrained black
              fashion designers who lack access and finances to study Fashion
              and Clothing Design formally at Universities. For her PhD study
              she is driven to identity factors that influence creativity and
              design quality amongst African (Black) untrained Designers whilst
              exploring design identity through a decolonised curriculum in
              Fashion Design. Her goal is to upskill such designers to a point
              where they run as sustainable business which are ready for retail
              and export opportunities. For her newest project, Fezile will be
              launching a Township based Fashion Week in 2020 which has been
              legally registered as ISINTU FASHION TRADE. This is an initiative
              aimed at creating market access opportunities for local designers,
              encouraging social cohesion, boosting local tourism, creating
              urbanisation and local economic development.
            </p>
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button
              onClick={() => setClassicModal(false)}
              color="danger"
              simple
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </GridItem>
    </GridContainer>
  );
}
