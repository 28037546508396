/* eslint-disable react/prop-types */
import React, { useContext } from "react";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import CircularProgress from "@material-ui/core/CircularProgress";

import { DocumentsContext } from "views/Dashboard/root/Store.js";
import Folders from "./Folders/List";
import AddFolder from "./Folders/Add.js";
import SubFolders from "./Folders/SubFolders/List";
import Documents from "./Folders/SubFolders/Documents/List";

export const DocumentsContent = props => {
  const [documents] = useContext(DocumentsContext);

  const {
    navigation,
    section,
    addFolderVisible,
    setNavigation,
    setSection,
    setAddFolderVisible
  } = props;

  return (
    <GridContainer justify="center">
      {documents.inProgress ? (
        <CircularProgress style={{ margin: "50px" }} />
      ) : (
        <GridItem xs={12} sm={12} md={12} style={{ height: "500px" }}>
          {section === "folders" && (
            <Folders
              navigation={navigation}
              setNavigation={setNavigation}
              setSection={setSection}
            />
          )}
          {section === "subfolders" && (
            <SubFolders
              navigation={navigation}
              setNavigation={setNavigation}
              setSection={setSection}
            />
          )}
          {section === "documents" && (
            <Documents navigation={navigation} setSection={setSection} />
          )}
        </GridItem>
      )}
      <AddFolder
        section={section}
        navigation={navigation}
        isVisible={addFolderVisible}
        setVisible={setAddFolderVisible}
      />
    </GridContainer>
  );
};

export default DocumentsContent;
