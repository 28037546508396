import { container, title } from "assets/jss/material-kit-react.js";

import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";

//import { container, title } from "assets/jss/material-kit-react.js";

import modalStyle from "assets/jss/material-kit-react/modalStyle.js";
import tooltipsStyle from "assets/jss/material-kit-react/tooltipsStyle.js";
import popoverStyles from "assets/jss/material-kit-react/popoverStyles.js";

const profilePageStyle = {
  section: {
    padding: "70px 0 0"
  },
  container,
  // title: {
  //   ...title,
  //   marginTop: "30px",
  //   minHeight: "32px",
  //   textDecoration: "none"
  // },
  icon: {
    width: "17px",
    height: "17px",
    marginRight: "4px"
  },
  ...modalStyle,
  modal: {
    minWidth: "350px"
  },
  label: {
    color: "rgba(0, 0, 0, 0.26)",
    cursor: "pointer",
    display: "inline-flex",
    fontSize: "14px",
    transition: "0.3s ease all",
    lineHeight: "1.428571429",
    fontWeight: "400",
    paddingLeft: "0",
    letterSpacing: "normal"
  },
  profile: {
    textAlign: "center",
    "& img": {
      height: "60px",
      width: "60px",
      margin: "0 auto",
      transform: "translate3d(0, -50%, 0)"
    }
  },
  description: {
    margin: "1.071rem auto 0",
    minWidth: "350px",
    color: "#999",
    textAlign: "center !important"
  },
  name: {
    marginTop: "-80px"
  },
  ...imagesStyle,
  imgFluid: {
    width: "70px",
    height: "70px",
    "&:hover": {
      transition: "transform .2s",
      transform: "scale(1)"
    }
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#999"
  },
  navWrapper: {
    margin: "20px auto 50px auto",
    textAlign: "center"
  },
  ...tooltipsStyle,
  ...popoverStyles
};

export default profilePageStyle;
