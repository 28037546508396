import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";

import { CoursesContext } from "views/Dashboard/root/Store.js";

import useStyles from "../../shared/utils/use-styles";
import FormControlSelect from "../../shared/components/dimensional-form-control-select/1D-form-control-select-pair";

// eslint-disable-next-line react/prop-types
const Course = ({ course, setCourse }) => {
  const [courses] = useContext(CoursesContext);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <FormControlSelect
          field={"id"}
          fieldName={"name"}
          label={"Select course"}
          index={false}
          options={[{ name: "" }].concat(courses.data)}
          classes={classes}
          objectDetails={course}
          setObjectDetails={setCourse}
        />
      </Grid>
    </div>
  );
};

export default Course;
