/* eslint-disable react/prop-types */
import React from "react";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const FormControlSelect = ({
  label,
  options,
  classes,
  index,
  setObjectDetails
}) => (
  <FormControl className={classes.formControl}>
    <InputLabel htmlFor={1}>{label}</InputLabel>
    <Select
      native
      autoWidth
      labelWidth={2}
      onChange={event => {
        setObjectDetails(options.find(o => o.name == event.target.value));
      }}
    >
      {options.map((prop, key) => (
        <option value={index ? key : prop.name} prop={prop} key={key}>
          {prop.name}
        </option>
      ))}
    </Select>
  </FormControl>
);

export default FormControlSelect;
