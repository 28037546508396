import React from "react";

import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import FMFA from "assets/img/affiliations/FMFA.jpg";
import FPMSETA from "assets/img/affiliations/FPMSETA.png";
import SAQA from "assets/img/affiliations/SAQA.png";
import QCTO from "assets/img/affiliations/QCTO.png";
import DFF from "assets/img/affiliations/DFF.jpg";
import TWYG from "assets/img/affiliations/TWYG.png";
import DURBAN_JULY from "assets/img/affiliations/DURBAN_JULY.png";

import styles from "assets/jss/material-kit-react/views/landingPageSections/affiliations.js";
import HeaderFooter from "./HeaderFooter.js";

const useStyles = makeStyles(styles);

export default function Affiliations(props) {
  const classes = useStyles();
  return (
    <HeaderFooter {...props}>
      <div id="affiliations" className={classes.section}>
        <h2 align="center" className={classes.title}>
          Affiliation & Accreditation
        </h2>
        <div className={classes.space50} />
        <div className={classes.container}>
          <GridContainer justify={"center"}>
            <GridItem sm={3} md={2}>
              <img
                src={FMFA}
                alt="..."
                className={
                  classes.imgRaised +
                  " " +
                  classes.imgRoundedCircle +
                  " " +
                  classes.imgFluid
                }
              />
            </GridItem>
            <GridItem sm={3} md={2}>
              <img
                src={FPMSETA}
                alt="..."
                className={
                  classes.imgRaised +
                  " " +
                  classes.imgRoundedCircle +
                  " " +
                  classes.imgFluid
                }
              />
            </GridItem>
            <GridItem sm={3} md={2}>
              <img
                src={SAQA}
                alt="..."
                className={
                  classes.imgRaised +
                  " " +
                  classes.imgRoundedCircle +
                  " " +
                  classes.imgFluid
                }
              />
            </GridItem>
            <GridItem sm={3} md={2}>
              <img
                src={DURBAN_JULY}
                alt="..."
                className={
                  classes.imgRaised +
                  " " +
                  classes.imgRoundedCircle +
                  " " +
                  classes.imgFluid
                }
              />
            </GridItem>
          </GridContainer>
          <div className={classes.space50} />
          <GridContainer justify={"center"}>
            <GridItem sm={3} md={2}>
              <img
                src={QCTO}
                alt="..."
                className={
                  classes.imgRaised +
                  " " +
                  classes.imgRoundedCircle +
                  " " +
                  classes.imgFluid
                }
              />
            </GridItem>
            <GridItem sm={3} md={2}>
              <img
                src={DFF}
                alt="..."
                className={
                  classes.imgRaised +
                  " " +
                  classes.imgRoundedCircle +
                  " " +
                  classes.imgFluid
                }
              />
            </GridItem>
            <GridItem sm={3} md={2}>
              <img
                src={TWYG}
                alt="..."
                className={
                  classes.imgRaised +
                  " " +
                  classes.imgRoundedCircle +
                  " " +
                  classes.imgFluid
                }
              />
            </GridItem>
          </GridContainer>
          <GridContainer />
        </div>
        <div className={classes.space50} />
      </div>
    </HeaderFooter>
  );
}
