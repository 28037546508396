import React, { useState, useContext } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
import Note from "@material-ui/icons/Note";
import Edit from "@material-ui/icons/Edit";
import NoteAddOutlined from "@material-ui/icons/NoteAddOutlined";
import People from "@material-ui/icons/People";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import Notifications from "@material-ui/icons/Notifications";
import AddAlertOutlined from "@material-ui/icons/AddAlertOutlined";
import ChatIcon from "@material-ui/icons/Chat";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/CustomParallax.js";

import profile from "assets/img/logo.png";
import styles from "assets/jss/material-kit-react/views/dashboard/admins/adminsPage.js";

import StudentsList from "./Students/List.js";
import Documents from "./Documents.js";
import Announcements from "./Announcements/List.js";
import AddAnnouncements from "./Announcements/Add.js";
import Chat from "./Chat.js";

import { CurrentUserContext, RemoteConfigContext } from "root/Store.js";
import {
  UsersContext,
  DocumentsContext,
  AnnouncementsContext,
  MessagesContext
} from "views/Dashboard/root/Store.js";
import * as documentPicker from "shared/utils/documentPicker";
import * as imagePicker from "shared/utils/imagePicker.js";
import * as api from "api/emailApi";

const useStyles = makeStyles(styles);

export default function Students(props) {
  const [currentuser] = useContext(CurrentUserContext);
  const [users] = useContext(UsersContext);
  const [announcements] = useContext(AnnouncementsContext);
  const [messages] = useContext(MessagesContext);
  const [documents, dispatch] = useContext(DocumentsContext);
  const [remoteConfig, setRemoteConfig] = useContext(RemoteConfigContext);

  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );

  const [section, setSection] = useState("course dates");
  const [AddAnnouncementVisible, showAddAnnouncement] = useState(false);

  const uploadDocument = async () => {
    if (currentuser.isAdmin) {
      await documentPicker.pick("students", dispatch);
      setSection("");
      api.sendMail({
        bcc: users.data.map(u => u.email),
        message: {
          subject: "FFSA Students Announcement",
          text:
            "Hi all \n\n New material has been uploaded. \n\n Regards \n FFSA Team"
        }
      });
    }
  };

  return (
    <div>
      <Header
        rightLinks={<HeaderLinks {...props} />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/profile-bg.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>
                      Fezile Fashion Skills Academy
                    </h3>
                    <h6>Students</h6>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                <NavPills
                  alignCenter
                  color="primary"
                  tabs={[
                    {
                      onClick: () => setSection("course dates"),
                      tabButton: "Course Dates",
                      tabIcon: section == "course dates" ? Edit : Note,
                      disabled: remoteConfig.inProgress,
                      tabContent: (
                        <GridContainer justify="center">
                          {remoteConfig.inProgress ? (
                            <CircularProgress style={{ margin: "20px" }} />
                          ) : (
                            <GridItem
                              xs={12}
                              sm={8}
                              md={8}
                              style={{ textAlign: "start" }}
                            >
                              {remoteConfig.data ? (
                                <div className="image-upload">
                                  <label htmlFor="file-input">
                                    <img
                                      src={remoteConfig.data.courseDates}
                                      alt="..."
                                      className={imageClasses}
                                    />
                                  </label>
                                  <input
                                    id="file-input"
                                    type="file"
                                    style={{ display: "none" }}
                                    onChange={e =>
                                      currentuser.isAdmin &&
                                      imagePicker.updateCourseDates(
                                        setRemoteConfig,
                                        e.target.files[0]
                                      )
                                    }
                                  />
                                </div>
                              ) : (
                                <p style={{ textAlign: "center" }}>
                                  Course updates not available!
                                </p>
                              )}
                            </GridItem>
                          )}
                        </GridContainer>
                      )
                    },
                    {
                      onClick: () => setSection("students"),
                      tabButton: "Students",
                      tabIcon: People,
                      tabContent: (
                        <GridContainer justify="center">
                          {users.inProgress ? (
                            <CircularProgress style={{ margin: "20px" }} />
                          ) : (
                            <GridItem xs={12} sm={12} md={8} lrg={8}>
                              <div>
                                <StudentsList />
                              </div>
                            </GridItem>
                          )}
                        </GridContainer>
                      )
                    },
                    {
                      onClick: () =>
                        section == "documents"
                          ? uploadDocument()
                          : setSection("documents"),
                      tabButton: "Documents",
                      tabIcon:
                        section == "documents" ? NoteAddOutlined : LibraryBooks,
                      tabContent: (
                        <GridContainer justify="center">
                          {documents.inProgress ? (
                            <CircularProgress style={{ margin: "20px" }} />
                          ) : (
                            <GridItem xs={12} sm={12} md={12}>
                              <Documents />
                            </GridItem>
                          )}
                        </GridContainer>
                      )
                    },
                    {
                      onClick: () =>
                        section == "announcements"
                          ? currentuser.isAdmin && showAddAnnouncement(true)
                          : setSection("announcements"),
                      tabButton: "Announcements",
                      tabIcon:
                        section == "announcements"
                          ? AddAlertOutlined
                          : Notifications,
                      tabContent: (
                        <GridContainer justify="center">
                          {announcements.inProgress ? (
                            <CircularProgress style={{ margin: "20px" }} />
                          ) : (
                            <GridItem xs={12} sm={12} md={12}>
                              <Announcements />
                              <AddAnnouncements
                                isVisible={AddAnnouncementVisible}
                                setVisible={showAddAnnouncement}
                              />
                            </GridItem>
                          )}
                        </GridContainer>
                      )
                    },
                    {
                      onClick: () => setSection("chatroom"),
                      tabButton: "Chat Room",
                      tabIcon: ChatIcon,
                      tabContent: (
                        <GridContainer justify="center">
                          {messages.inProgress ? (
                            <CircularProgress style={{ margin: "20px" }} />
                          ) : (
                            <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                              style={{ height: "500px" }}
                            >
                              <Chat />
                            </GridItem>
                          )}
                        </GridContainer>
                      )
                    }
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
