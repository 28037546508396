export default [
  require("assets/img/student-life/1.jpg"),
  require("assets/img/student-life/3.jpg"),
  require("assets/img/student-life/4.jpg"),
  require("assets/img/student-life/5.jpg"),
  require("assets/img/student-life/6.jpg"),
  require("assets/img/student-life/7.jpg"),
  require("assets/img/student-life/8.jpg"),
  require("assets/img/student-life/9.jpg"),
  require("assets/img/student-life/10.jpg"),
  require("assets/img/student-life/11.jpg"),
  require("assets/img/student-life/12.jpg"),
  require("assets/img/student-life/13.jpg"),
  require("assets/img/student-life/14.jpg"),
  require("assets/img/student-life/15.jpg"),
  require("assets/img/student-life/16.jpg"),
  require("assets/img/student-life/17.jpg"),
  require("assets/img/student-life/18.jpg"),
  require("assets/img/student-life/19.jpg"),
  require("assets/img/student-life/20.jpg"),
  require("assets/img/student-life/21.jpg"),
  require("assets/img/student-life/22.jpg"),
  require("assets/img/student-life/23.jpg"),
  require("assets/img/student-life/24.jpg"),
  require("assets/img/student-life/25.jpg"),
  require("assets/img/student-life/26.jpg"),
  require("assets/img/student-life/27.jpg"),
  require("assets/img/student-life/28.jpg"),
  require("assets/img/student-life/29.jpg"),
  require("assets/img/student-life/30.jpg"),
  require("assets/img/student-life/31.jpg"),
  require("assets/img/student-life/32.jpg"),
  require("assets/img/student-life/33.jpg"),
  require("assets/img/student-life/34.jpg"),
  require("assets/img/student-life/35.jpg"),
  require("assets/img/student-life/36.jpg")
];
