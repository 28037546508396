import "./index.css";

import React, { Component } from "react";

import { Map, Marker, GoogleApiWrapper } from "google-maps-react";

import appsettings from "appsettings.json";

export class MapContainer extends Component {
  render() {
    return (
      <Map
        className="maps"
        // eslint-disable-next-line react/prop-types
        google={this.props.google}
        zoom={5}
        initialCenter={{ lat: -28.293846, lng: 29.14836 }}
      >
        <Marker
          key={1}
          title={"Durban branch"}
          position={{ lat: -29.8397266, lng: 31.0200551 }}
        />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: appsettings.MapsApiKey
})(MapContainer);
