import React, { useEffect, useContext } from "react";
import { GiftedChat } from "react-web-gifted-chat";

import { CurrentUserContext } from "root/Store.js";
import { MessagesContext } from "../root/Store.js";
import * as api from "../api/index.js";

export const ChatRoom = props => {
  const [currentUser] = useContext(CurrentUserContext);
  const [messages] = useContext(MessagesContext);
  const parentId = "ffsa";

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    if (!currentUser) props.history && props.history.push("/login");
  }, []);

  const data = messages.data.filter(m => m.parentId == parentId);

  return (
    <span>
      {!messages.inProgress && (
        <GiftedChat
          renderUsernameOnMessage
          isLoadingEarlier
          messages={data
            .sort((a, b) => b.createdAt - a.createdAt)
            .map(d => ({ ...d, createdAt: d.createdAt }))}
          onSend={mssgs => {
            api.setData("messages", {
              ...mssgs[0],
              parentId: parentId
            });
          }}
          user={{ ...currentUser, _id: currentUser && currentUser.id }}
        />
      )}
    </span>
  );
};

export default ChatRoom;
