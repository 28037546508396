/* eslint-disable react/prop-types */
import React, { useContext } from "react";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Fingerprint from "@material-ui/icons/Fingerprint";
import Check from "@material-ui/icons/Check";
import Book from "@material-ui/icons/LibraryBooks";
import Updates from "@material-ui/icons/AutorenewOutlined";
import Users from "@material-ui/icons/PeopleOutline";
import Lock from "@material-ui/icons/LockOutlined";
import Accessibility from "@material-ui/icons/Accessibility";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import WhatsAppFloatingButton from "components/WhatsAppFloatingBtn";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { CurrentUserContext } from "root/Store.js";

// Sections for this page

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

const navigateSecuredTab = (currentUser, props, redirect) => {
  if (currentUser) {
    if (currentUser.isAdmin) props.history.push(redirect);
    else toast.error("No Access.");
  } else if (!currentUser) props.history.push("/login");
};

const navigate = (currentUser, props, redirect) => {
  if (currentUser) props.history.push(redirect);
  else props.history.push("/login");
};

export default function LandingPage(props) {
  const [currentUser] = useContext(CurrentUserContext);
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        /*color="transparent"*/
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks {...props} />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "white"
        }}
        {...rest}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer
            justify="center"
            id="dashboardContainer"
            style={{
              paddingTop: isMobile ? "10vh" : "20vh",
              paddingBottom: "30vw"
            }}
          >
            <GridItem
              xs={12}
              sm={6}
              md={4}
              lg={3}
              onClick={() => navigateSecuredTab(currentUser, props, "/admins")}
            >
              <Card className={"card-admins"}>
                <CardHeader color="warning" stats icon>
                  <CardIcon color="warning">
                    <Fingerprint>content_copy</Fingerprint>
                  </CardIcon>
                  <h3
                    style={{ textAlign: "center", fontSize: "unset" }}
                    className={classes.cardTitle}
                  >
                    Admins
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <Lock />
                    Private use
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem
              xs={12}
              sm={6}
              md={4}
              lg={3}
              onClick={() => navigate(currentUser, props, "/courses")}
            >
              <Card>
                <CardHeader color="success" stats icon>
                  <CardIcon color="success">
                    <Book />
                  </CardIcon>
                  <h3
                    style={{ textAlign: "center", fontSize: "unset" }}
                    className={classes.cardTitle}
                  >
                    Courses
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <Check />
                    Registered Students
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem
              xs={12}
              sm={6}
              md={4}
              lg={3}
              onClick={() => navigate(currentUser, props, "/applications")}
            >
              <Card>
                <CardHeader color="danger" stats icon>
                  <CardIcon color="danger">
                    <Book />
                  </CardIcon>
                  <h3
                    style={{ textAlign: "center", fontSize: "unset" }}
                    className={classes.cardTitle}
                  >
                    Applications
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <Updates />
                    Application Status
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem
              xs={12}
              sm={6}
              md={4}
              lg={3}
              onClick={() => navigate(currentUser, props, "/students")}
            >
              <Card>
                <CardHeader color="info" stats icon>
                  <CardIcon color="info">
                    <Accessibility />
                  </CardIcon>
                  <h3
                    style={{ textAlign: "center", fontSize: "unset" }}
                    className={classes.cardTitle}
                  >
                    Students
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <Users />
                    {" Students"}
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <WhatsAppFloatingButton />
      <Footer />
    </div>
  );
}
