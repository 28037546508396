import React, { useState, useContext, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
import Note from "@material-ui/icons/Note";
import Edit from "@material-ui/icons/Edit";
import NoteAddOutlined from "@material-ui/icons/NoteAddOutlined";
import People from "@material-ui/icons/People";
import PersonAdd from "@material-ui/icons/PersonAdd";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import Notifications from "@material-ui/icons/Notifications";
import AddAlertOutlined from "@material-ui/icons/AddAlertOutlined";
import ChatIcon from "@material-ui/icons/Chat";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/CustomParallax.js";

import profile from "assets/img/logo.png";
import styles from "assets/jss/material-kit-react/views/dashboard/admins/adminsPage.js";

import appsettings from "appsettings.json";

import EditDescriptionModal from "./Sections/EditDescriptionModal.js";
import StaffList from "./Sections/Staff/List.js";
import StaffAdd from "./Sections/Staff/Add.js";
import Documents from "./Sections/Documents.js";
import Announcements from "./Sections/Announcements/List.js";
import AddAnnouncements from "./Sections/Announcements/Add.js";
import Chat from "./Sections/Chat.js";

import {
  ClientsContext,
  DocumentsContext,
  AnnouncementsContext,
  MessagesContext
} from "./root/Store.js";
import { UsersContext } from "views/Dashboard/root/Store.js";
import { paragraph } from "shared/utils/stringExtentions.js";
import * as documentPicker from "./shared/utils/documentPicker";
import * as api from "./api";
import * as mailApi from "api/emailApi";

const useStyles = makeStyles(styles);

export default function Admins(props) {
  const [clients] = useContext(ClientsContext);
  const [users] = useContext(UsersContext);
  const [announcements] = useContext(AnnouncementsContext);
  const [messages] = useContext(MessagesContext);
  const [documents, dispatch] = useContext(DocumentsContext);
  const client = clients.data.find(c => c.name == appsettings.appName);

  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );

  const [section, setSection] = useState("description");
  const [EditDescription, setEditDescription] = useState(false);
  const [AddStaffVisible, showAddStaff] = useState(false);
  const [AddAnnouncementVisible, showAddAnnouncement] = useState(false);

  useEffect(() => {
    api.login();
    return;
  }, []);

  const uploadDocument = async () => {
    await documentPicker.pick("ffsa", dispatch);
    setSection("");
    mailApi.sendMail({
      to: users.data.filter(u => u.isAdmin).map(u => u.email),
      message: {
        subject: "FFSA Admins Announcement",
        text:
          "Hi admins \n\n A new document has been uploaded. \n\n Regards \n FFSA Team"
      }
    });
  };

  return (
    <div>
      <Header
        rightLinks={<HeaderLinks {...props} />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/profile-bg.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>
                      Fezile Fashion Skills Academy
                    </h3>
                    <h6>Admins</h6>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                <NavPills
                  alignCenter
                  color="primary"
                  tabs={[
                    {
                      onClick: () =>
                        section == "description"
                          ? setEditDescription(true)
                          : setSection("description"),
                      tabButton: "Description",
                      tabIcon: section == "description" ? Edit : Note,
                      disabled: clients.inProgress,
                      tabContent: (
                        <GridContainer justify="center">
                          {clients.inProgress ? (
                            <CircularProgress style={{ margin: "20px" }} />
                          ) : (
                            <GridItem
                              xs={12}
                              sm={4}
                              md={4}
                              style={{ textAlign: "start" }}
                            >
                              <div>
                                {client && client.description
                                  ? paragraph(client.description)
                                  : "No description."}
                                <EditDescriptionModal
                                  isVisible={EditDescription}
                                  setVisible={setEditDescription}
                                />
                              </div>
                            </GridItem>
                          )}
                        </GridContainer>
                      )
                    },
                    {
                      onClick: () =>
                        section == "staff"
                          ? showAddStaff(true)
                          : setSection("staff"),
                      tabButton: "Staff",
                      tabIcon: section == "staff" ? PersonAdd : People,
                      disabled: users.inProgress,
                      tabContent: (
                        <GridContainer justify="center">
                          {users.inProgress ? (
                            <CircularProgress style={{ margin: "20px" }} />
                          ) : (
                            <GridItem xs={12} sm={6} md={6}>
                              <div>
                                <StaffList />
                                <StaffAdd
                                  isVisible={AddStaffVisible}
                                  setVisible={showAddStaff}
                                />
                              </div>
                            </GridItem>
                          )}
                        </GridContainer>
                      )
                    },
                    {
                      onClick: () =>
                        section == "documents"
                          ? uploadDocument()
                          : setSection("documents"),
                      tabButton: "Documents",
                      tabIcon:
                        section == "documents" ? NoteAddOutlined : LibraryBooks,
                      tabContent: (
                        <GridContainer justify="center">
                          {documents.inProgress ? (
                            <CircularProgress style={{ margin: "20px" }} />
                          ) : (
                            <GridItem xs={12} sm={12} md={12}>
                              <Documents />
                            </GridItem>
                          )}
                        </GridContainer>
                      )
                    },
                    {
                      onClick: () =>
                        section == "announcements"
                          ? showAddAnnouncement(true)
                          : setSection("announcements"),
                      tabButton: "Announcements",
                      tabIcon:
                        section == "announcements"
                          ? AddAlertOutlined
                          : Notifications,
                      tabContent: (
                        <GridContainer justify="center">
                          {announcements.inProgress ? (
                            <CircularProgress style={{ margin: "20px" }} />
                          ) : (
                            <GridItem xs={12} sm={12} md={12}>
                              <Announcements />
                              <AddAnnouncements
                                isVisible={AddAnnouncementVisible}
                                setVisible={showAddAnnouncement}
                              />
                            </GridItem>
                          )}
                        </GridContainer>
                      )
                    },
                    {
                      onClick: () => setSection("chatroom"),
                      tabButton: "Chat Room",
                      tabIcon: ChatIcon,
                      tabContent: (
                        <GridContainer justify="center">
                          {messages.inProgress ? (
                            <CircularProgress style={{ margin: "20px" }} />
                          ) : (
                            <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                              style={{ height: "500px" }}
                            >
                              <Chat />
                            </GridItem>
                          )}
                        </GridContainer>
                      )
                    }
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
