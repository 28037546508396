export default [
  require("assets/img/campuses/1.jpg"),
  require("assets/img/campuses/2.jpg"),
  require("assets/img/campuses/3.jpg"),
  require("assets/img/campuses/4.jpg"),
  require("assets/img/campuses/5.jpg"),
  require("assets/img/campuses/6.jpg"),
  require("assets/img/campuses/7.jpg"),
  require("assets/img/campuses/8.jpg"),
  require("assets/img/campuses/9.jpg"),
  require("assets/img/campuses/10.jpg"),
  require("assets/img/campuses/11.jpg")
];
