/* eslint-disable react/prop-types */
import React, { useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-kit-react/views/dashboard/admins/editDescription.js";

import * as api from "api/index.js";
import { toast } from "react-toastify";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function Add(props) {
  const [video, setVideo] = useState({ name: "", refId: "" });
  const classes = useStyles();
  const { isVisible, setVisible, navigation } = props;
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} lg={4}>
            <Dialog
              classes={{
                root: classes.center,
                paper: classes.modal
              }}
              open={isVisible}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => setVisible(false)}
              aria-labelledby="classic-modal-slide-title"
              aria-describedby="classic-modal-slide-description"
            >
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <IconButton
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={() => setVisible(false)}
                >
                  <Close className={classes.modalClose} />
                </IconButton>
                <h4 align={"center"} className={classes.modalTitle}>
                  New Video
                </h4>
              </DialogTitle>
              <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
              >
                <CustomInput
                  labelText="Video name..."
                  value={video.name}
                  onChangeValue={value => setVideo({ ...video, name: value })}
                  formControlProps={{
                    fullWidth: true
                  }}
                  multiline={false}
                />
                <CustomInput
                  labelText="url e.g https://dai.ly/k7eNCkTbgGUIACw3FC3..."
                  value={video.refId}
                  onChangeValue={value => setVideo({ ...video, refId: value })}
                  formControlProps={{
                    fullWidth: true
                  }}
                  multiline={false}
                />
              </DialogContent>
              <DialogActions className={classes.modalFooter}>
                <Button
                  color="success"
                  disabled={video.name === "" || video.refId === ""}
                  simple
                  onClick={() => {
                    api.setData("videos", {
                      name: video.name,
                      refId: video.refId.split("/")[
                        video.refId.split("/").length - 1
                      ],
                      isLocked: true,
                      parentId: navigation.subFolderId
                    });
                    setVisible(false);
                    toast.success("Video added successfully.");
                  }}
                >
                  Add
                </Button>
                <Button onClick={() => setVisible(false)} color="danger" simple>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
