import React from "react";
import Carousel from "react-slick";

import one from "assets/img/banners/0.png";
import two from "assets/img/banners/2.jpg";

export default function Parallax() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };
  return (
    <Carousel {...settings} className={"parallex"} style={{ marginTop: 70 }}>
      <img src={one} alt="First slide" className="slick-image" />
      <img src={two} alt="Second slide" className="slick-image" />
    </Carousel>
  );
}
