import React, { useContext, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Clearfix from "components/Clearfix/Clearfix.js";

import View from "./View.js";
import styles from "assets/jss/material-kit-react/views/componentsSections/notificationsStyles.js";
import { AnnouncementsContext } from "views/Dashboard/root/Store.js";

const useStyles = makeStyles(styles);

export default function CourseAnnouncements(props) {
  const [announcements] = useContext(AnnouncementsContext);
  const data = announcements.data.filter(
    // eslint-disable-next-line react/prop-types
    a => a.parentId == props.courseId
  );
  const [viewVisible, setViewVisible] = useState(false);
  const [announcement, setAnnouncement] = useState("");
  const classes = useStyles();

  const openView = announcement => {
    setAnnouncement(announcement);
    setViewVisible(true);
  };

  return (
    <div
      className={classes.section}
      id="notifications"
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
      }}
    >
      {data
        .sort((a, b) => b.createdAt - a.createdAt)
        .map((announcement, key) => (
          <span key={key} onClick={() => openView(announcement.message)}>
            <SnackbarContent
              key={key}
              message={
                <span style={{ color: "black", maxWidth: "100px" }}>
                  <b>{announcement.message}</b>
                </span>
              }
              color="black"
              icon="info_outline"
            />
          </span>
        ))}
      <View
        announcement={announcement}
        isVisible={viewVisible}
        setVisible={setViewVisible}
      />
      <div center style={{ marginTop: 10 }}>
        {data.length == 0 && "No announcements."}
      </div>
      <Clearfix />
    </div>
  );
}
