/* eslint-disable react/prop-types */
import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-kit-react/views/dashboard/admins/editDescription.js";

import * as api from "api/index.js";
import * as emailApi from "api/emailApi.js";
import * as validation from "shared/utils/validations.js";
import * as emailConstructor from "shared/utils/emailConstructor.js";
import colors from "shared/constants/colors.js";
import appsettings from "appsettings.json";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function EditStatusModal(props) {
  const {
    isVisible,
    setVisible,
    application,
    setApplication,
    statusBefore
  } = props;
  const classes = useStyles();

  const onUpdate = () => {
    if (validation.validate(application, ["status", "color"])) {
      api.update("applications", application.id, application);

      emailApi.sendMail({
        to: application.studentDetails.email,
        cc: appsettings[appsettings.environment].sendToEmail,
        message: {
          subject: "FFSA Application Status Update",
          text: emailConstructor.ApplicationStatusUpdate(
            application,
            statusBefore
          )
        }
      });

      setVisible(false);
    }
  };

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} lg={4}>
            <Dialog
              classes={{
                root: classes.center,
                paper: classes.modal
              }}
              open={isVisible}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => setVisible(false)}
              aria-labelledby="classic-modal-slide-title"
              aria-describedby="classic-modal-slide-description"
            >
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <IconButton
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={() => setVisible(false)}
                >
                  <Close className={classes.modalClose} />
                </IconButton>
                <h4 align={"center"} className={classes.modalTitle}>
                  Update Status
                </h4>
              </DialogTitle>
              <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
              >
                <CustomInput
                  id="status"
                  value={application.status}
                  onChangeValue={value =>
                    setApplication({ ...application, status: value })
                  }
                  formControlProps={{
                    fullWidth: true
                  }}
                />
                <div className={classes.title}>
                  <h3>
                    <small>
                      Status color
                      {application.color && " : " + application.color}
                    </small>
                  </h3>
                </div>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={12}>
                    <Button
                      style={{ backgroundColor: colors.pink }}
                      onClick={() =>
                        setApplication({ ...application, color: "pink" })
                      }
                    >
                      Pink
                    </Button>
                    <Button
                      style={{ backgroundColor: colors.golden }}
                      onClick={() =>
                        setApplication({ ...application, color: "golden" })
                      }
                    >
                      Golden
                    </Button>
                    <Button
                      style={{ backgroundColor: colors.green }}
                      onClick={() =>
                        setApplication({ ...application, color: "green" })
                      }
                    >
                      Green
                    </Button>
                    <Button
                      style={{ backgroundColor: colors.gray }}
                      onClick={() =>
                        setApplication({ ...application, color: "gray" })
                      }
                    >
                      Gray
                    </Button>
                    <Button
                      style={{ backgroundColor: colors.blue }}
                      onClick={() =>
                        setApplication({ ...application, color: "blue" })
                      }
                    >
                      Blue
                    </Button>
                    <Button
                      style={{ backgroundColor: colors.darkBlue }}
                      onClick={() =>
                        setApplication({ ...application, color: "darkBlue" })
                      }
                    >
                      Dark Blue
                    </Button>
                    <Button
                      style={{ backgroundColor: colors.lightBlue }}
                      onClick={() =>
                        setApplication({ ...application, color: "lightBlue" })
                      }
                    >
                      Light Blue
                    </Button>
                  </GridItem>
                </GridContainer>
              </DialogContent>
              <DialogActions className={classes.modalFooter}>
                <Button color="success" simple onClick={() => onUpdate()}>
                  Submit
                </Button>
                <Button onClick={() => setVisible(false)} color="danger" simple>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
