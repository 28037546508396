import React from "react";

export const paragraph = text =>
  text.split("\n\n").map((item, i) => {
    return <p key={i}>{bodyText(item)}</p>;
  });
export const bodyText = text =>
  text.split("\n").map((item, i) => {
    return isUpperCase(item) ? (
      <span key={i} style={titleStyle}>
        {item}
        <br />
      </span>
    ) : (
      <span key={i}>{item}</span>
    );
  });

const titleStyle = {
  "text-transform": "uppercase",
  color: "#56b383",
  "font-weight": "bold"
};

const isUpperCase = str => {
  return str === str.toUpperCase();
};
