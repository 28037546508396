/* eslint-disable react/prop-types */
import React from "react";

export default function Qualification(props) {
  const [coursesVisible, setCoursesVisible] = React.useState(false);

  const { qualification, color, children } = props;

  return (
    <div
      onClick={() => setCoursesVisible(true)}
      onMouseLeave={() => setCoursesVisible(false)}
      style={{
        height: 150,
        marginInline: 20,
        borderRadius: 5,
        textAlign: "center",
        display: coursesVisible ? "initial" : "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 20,
        background: `linear-gradient(60deg, ${color}, white)`,
        boxShadow:
          "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
      }}
    >
      <h5
        style={{ marginBottom: 50, fontWeight: "bold", color: coursesVisible ? color : "white" }}
      >
        {qualification}
      </h5>
      {coursesVisible && children}
    </div>
  );
}
