import React from "react";

import Carousel from "./Sections/Carousel.js";

import HeaderFooter from "./Sections/HeaderFooter.js";

export default function LandingPage(props) {
  return (
    <HeaderFooter {...props} isLandingPage>
      <Carousel />
    </HeaderFooter>
  );
}
