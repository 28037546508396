/* eslint-disable react/prop-types */
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";

// core components
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-kit-react/views/dashboard/admins/staffPage.js";

import { VideosContext } from "views/Dashboard/Sections/Courses/root/Store.js";
import { CurrentUserContext } from "root/Store.js";
import View from "./View.js";
import Edit from "./Edit.js";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function Videos(props) {
  const [videos] = useContext(VideosContext);
  const [currentUser] = useContext(CurrentUserContext);
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [EditVideoVisible, setEditVideoVisible] = useState(false);
  const [videoVisible, setVideoVisible] = useState(false);
  const [video, setVideo] = useState();

  const data = videos.data.filter(
    v =>
      v.parentId == props.navigation.subFolderId &&
      v.name.toLowerCase().includes(search.toLowerCase())
  );

  const openVideo = video => {
    setVideo(video);
    setVideoVisible(true);
  };
  const editVideo = video => {
    setVideo(video);
    setEditVideoVisible(true);
  };

  return (
    <div className={classes.section} id="notifications">
      <div className={classes.container} style={{ maxHeight: "500px" }}>
        <span style={{ display: "flex" }}>
          <CustomInput
            labelText="Search..."
            id="Search"
            value={search}
            onChangeValue={value => setSearch(value)}
            formControlProps={{
              fullWidth: true
            }}
          />
          <ArrowBackIos
            onClick={() => {
              props.setSection("subfolders");
            }}
            style={{ marginTop: "30px" }}
          />
        </span>
        {data
          .sort((a, b) => b.createdAt - a.createdAt)
          .map((video, key) => (
            <span
              onClick={() =>
                video.isLocked
                  ? toast.info("This Video is locked!")
                  : openVideo(video)
              }
              key={key}
            >
              <SnackbarContent
                iconClick={event => {
                  event.stopPropagation();
                  currentUser.isAdmin && editVideo(video);
                }}
                message={
                  <span style={{ color: "black" }}>
                    <b>{video.name}</b>
                  </span>
                }
                color="black"
                icon={video.isLocked ? "lock_outline" : "videocam"}
              />
            </span>
          ))}
        <div center style={{ marginTop: 10 }}>
          {data.length == 0 && "No videos."}
        </div>
        <View
          video={video}
          isVisible={videoVisible}
          setVisible={setVideoVisible}
        />
        <Edit
          video={video}
          isVisible={EditVideoVisible}
          setVisible={setEditVideoVisible}
        />
      </div>
    </div>
  );
}
