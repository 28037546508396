import React, { useReducer, useEffect } from "react";

import * as api from "../api/index.js";
import * as rootReducer from "shared/utils/rootReducer";

export const AnnouncementsContext = React.createContext();
export const DocumentsContext = React.createContext();
export const MessagesContext = React.createContext();
export const ClientsContext = React.createContext();

const initalState = {
  data: [],
  search: "",
  inProgress: true
};

// eslint-disable-next-line react/prop-types
const Store = ({ children }) => {
  const [announcements, dispatchAnnouncements] = useReducer(
    rootReducer.setStateReducer,
    initalState
  );
  const [documents, dispatchDocuments] = useReducer(
    rootReducer.setStateReducer,
    initalState
  );
  const [messages, dispatchMessages] = useReducer(
    rootReducer.setStateReducer,
    initalState
  );
  const [clients, dispatchClients] = useReducer(
    rootReducer.setStateReducer,
    initalState
  );

  useEffect(() => {
    api.getCollection("announcements", dispatchAnnouncements);
    api.getCollection("documents", dispatchDocuments);
    api.getCollection("messages", dispatchMessages);
    api.getCollection("clients", dispatchClients);
  }, []);

  return (
    <AnnouncementsContext.Provider
      value={[announcements, dispatchAnnouncements]}
    >
      <DocumentsContext.Provider value={[documents, dispatchDocuments]}>
        <MessagesContext.Provider value={[messages, dispatchMessages]}>
          <ClientsContext.Provider value={[clients, dispatchClients]}>
            {children}
          </ClientsContext.Provider>
        </MessagesContext.Provider>
      </DocumentsContext.Provider>
    </AnnouncementsContext.Provider>
  );
};
export default Store;
