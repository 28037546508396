import { container } from "assets/jss/material-kit-react.js";
import { title } from "assets/jss/material-kit-react.js";

const campuseslStyle = {
  section: {
    padding: "70px 0"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    fontSize: "x-large"
  },
  container,
  marginAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important"
  }
};

export default campuseslStyle;
