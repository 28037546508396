/* eslint-disable react/prop-types */
import React, { useContext } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Clearfix from "components/Clearfix/Clearfix.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/notificationsStyles.js";
import { FoldersContext } from "views/Dashboard/Sections/Courses/root/Store.js";

const useStyles = makeStyles(styles);

export default function List(props) {
  const [folders] = useContext(FoldersContext);
  const data = folders.data.filter(
    f => f.parentId == props.navigation.courseId && f.section == "videos"
  );
  const { navigation, setNavigation, setSection } = props;
  const classes = useStyles();

  return (
    <div
      className={classes.section}
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
      }}
    >
      {data
        .sort((a, b) => b.createdAt - a.createdAt)
        .map((folder, key) => (
          <span
            key={key}
            onClick={() => {
              setNavigation({ ...navigation, folderId: folder.id });
              setSection("subfolders");
            }}
          >
            <SnackbarContent
              key={key}
              message={
                <span style={{ color: "black", maxWidth: "100px" }}>
                  <b>{folder.name}</b>
                </span>
              }
              color="black"
              icon="folder_outline"
            />
          </span>
        ))}
      <div center style={{ marginTop: 10 }}>
        {data.length == 0 && "No folders."}
      </div>
      <Clearfix />
    </div>
  );
}
