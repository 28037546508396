/* eslint-disable react/prop-types */
import React, { useContext } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/dashboard/courses/videos/view.js";

import { CurrentUserContext } from "root/Store";
import * as api from "api";
import { toast } from "react-toastify";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function Edit(props) {
  const [currentUser] = useContext(CurrentUserContext);
  const { video } = props;
  const classes = useStyles();
  const { isVisible, setVisible } = props;

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} lg={4}>
            <Dialog
              classes={{
                root: classes.center,
                paper: classes.modal
              }}
              open={isVisible}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => setVisible(false)}
              aria-labelledby="classic-modal-slide-title"
              aria-describedby="classic-modal-slide-description"
            >
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <IconButton
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={() => setVisible(false)}
                >
                  <Close className={classes.modalClose} />
                </IconButton>
              </DialogTitle>
              <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
              >
                {video && (
                  <iframe
                    frameBorder="0"
                    src={`https://www.dailymotion.com/embed/video/${video.refId}?queue-enable=false&sharing-enable=false&`}
                    fullscreen
                    allowFullScreen
                    allow="autoplay"
                  />
                )}
              </DialogContent>
              <DialogActions className={classes.modalFooter}>
                {!currentUser.isAdmin && (
                  <Button
                    onClick={() => {
                      api.removeData("videos", video.id);
                      setVisible(false);
                      toast.success("Video deleted successfully");
                    }}
                    color="danger"
                    simple
                  >
                    Delete
                  </Button>
                )}
              </DialogActions>
            </Dialog>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
