/* eslint-disable react/prop-types */
import React, { useContext, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-kit-react/views/dashboard/admins/editDescription.js";

import * as api from "api/index.js";
import * as validation from "shared/utils/validations.js";
import { FeesContext } from "views/Dashboard/root/Store.js";
import { toast } from "react-toastify";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function EditFees(props) {
  const [fees] = useContext(FeesContext);
  const { course } = props;
  const classes = useStyles();
  const { isVisible, setVisible } = props;
  const data = fees.data.filter(fee => fee.courseId == course.id);

  const [addVisible, setAddVisible] = useState(false);
  const [newFee, setNewFee] = useState({ amount: 0 });

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} lg={4}>
            <Dialog
              classes={{
                root: classes.center,
                paper: classes.modal
              }}
              open={isVisible}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => setVisible(false)}
              aria-labelledby="classic-modal-slide-title"
              aria-describedby="classic-modal-slide-description"
            >
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <IconButton
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={() => setVisible(false)}
                >
                  <Close className={classes.modalClose} />
                </IconButton>
                <h4 align={"center"} className={classes.modalTitle}>
                  {course && course.name + " FEES"}
                </h4>
              </DialogTitle>
              <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
              >
                {addVisible && (
                  <GridContainer>
                    <GridItem xs={6} sm={6} md={6} lg={6}>
                      <CustomInput
                        labelText="Fee name..."
                        value={newFee.name}
                        onChangeValue={value =>
                          setNewFee({ ...newFee, name: value })
                        }
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6} lg={6}>
                      <CustomInput
                        value={newFee.amount}
                        onChangeValue={value =>
                          !isNaN(value) &&
                          setNewFee({ ...newFee, amount: value })
                        }
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                )}
                {data
                  .sort((a, b) => b.createdAt - a.createdAt)
                  .map((fee, key) => (
                    <GridContainer
                      style={{ justifyContent: "center" }}
                      key={key}
                    >
                      <GridItem xs={4} sm={4} md={4} lg={4}>
                        <div style={{ marginTop: "30px" }}>{fee.name}</div>
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4} lg={4}>
                        <CustomInput
                          value={"R" + fee.amount}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={2} sm={2} md={2} lg={2}>
                        <div className={classes.title}>
                          <Button
                            onClick={() => {
                              api.removeData("fees", fee.id);
                              toast.success("Fee removed successfully");
                            }}
                            color="danger"
                            simple
                          >
                            Delete
                          </Button>
                        </div>
                      </GridItem>
                    </GridContainer>
                  ))}
                {data.length == 0 && (
                  <h6 align={"center"} style={{ color: "red" }}>
                    No fees added for this course.
                  </h6>
                )}
              </DialogContent>
              <DialogActions className={classes.modalFooter}>
                <Button color="info" simple onClick={() => setAddVisible(true)}>
                  Add
                </Button>
                <Button
                  color="success"
                  simple
                  onClick={() => {
                    if (validation.validate(newFee, ["name", "amount"])) {
                      api.setData("fees", {
                        ...newFee,
                        courseId: props.course.id
                      });
                      setAddVisible(false);
                      toast.success("Fee added successfully");
                    }
                  }}
                >
                  Save
                </Button>
                <Button onClick={() => setVisible(false)} color="danger" simple>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
