import firebase from "firebase";
import { toast } from "react-toastify";

import appsettings from "appsettings.json";
import { update } from "./index.js";

if (!firebase.apps.length)
  firebase.initializeApp(appsettings[appsettings.environment].firebaseConfig);

export const updateAuthUser = async data =>
  firebase.auth().currentUser.updateProfile({
    ...data
  });

export const signOut = () => firebase.auth().signOut();

export const login = (user, setInProgress, props) =>
  firebase
    .auth()
    .signInWithEmailAndPassword(user.email, user.password)
    .then(
      response => {
        setInProgress(false);
        props.history.push("/");
        toast.success("Welcome " + response.user.displayName);
      },
      error => {
        setInProgress(false);
        toast.error(error.message);
      }
    );

export const signup = (user, setInProgress, props) =>
  firebase
    .auth()
    .createUserWithEmailAndPassword(user.email, user.password)
    .then(
      response => {
        setInProgress(false);
        updateUserName(user, props);
        registerUser(user, response);
      },
      error => {
        setInProgress(false);
        toast.error(error.message);
      }
    );

const updateUserName = (user, props) => {
  firebase
    .auth()
    .currentUser.updateProfile({
      displayName: user.name
    })
    .then(() => {
      toast.success("Welcome " + user.name);
      props.history.push("/");
    });
};

const registerUser = (user, response) => {
  const uid = response.user.email.replace(/[^0-9a-z]/gi, "");
  update("users", uid, {
    ...user,
    id: uid,
    uid: response.user.uid,
    password: "*********",
    createdAt: Date.now()
  });
};
