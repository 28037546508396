import React, { useContext, useState } from "react";
import { isMobile } from "react-device-detect";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Clearfix from "components/Clearfix/Clearfix.js";

import View from "./View.js";
import styles from "assets/jss/material-kit-react/views/componentsSections/notificationsStyles.js";
import { AnnouncementsContext } from "views/Dashboard/root/Store.js";

const useStyles = makeStyles(styles);

export default function SectionNotifications() {
  const [announcements] = useContext(AnnouncementsContext);
  const data = announcements.data.filter(a => a.parentId == "students");
  const [viewVisible, setViewVisible] = useState(false);
  const [announcement, setAnnouncement] = useState("");
  const classes = useStyles();

  const openView = announcement => {
    setAnnouncement(announcement);
    setViewVisible(true);
  };
  return (
    <div
      className={classes.section}
      id="notifications"
      style={{
        whiteSpace: "nowrap",
        maxHeight: "500px"
      }}
    >
      {data
        .sort((a, b) => b.createdAt - a.createdAt)
        .map((announcement, key) => (
          <span key={key} onClick={() => openView(announcement.message)}>
            <SnackbarContent
              message={
                <span
                  style={{
                    color: "black",
                    display: "inline-block",
                    textAlign: "center",
                    width: isMobile ? "200px" : "300px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis"
                  }}
                >
                  <b>{announcement.message}</b>
                </span>
              }
              color="black"
              icon="info_outline"
            />
          </span>
        ))}
      <View
        announcement={announcement}
        isVisible={viewVisible}
        setVisible={setViewVisible}
      />
      <div center style={{ marginTop: 10 }}>
        {data.length == 0 && "No announcements."}
      </div>
      <Clearfix />
    </div>
  );
}
