export const ApplicationStatusUpdate = (application, oldStatus) => 
    "Hi " + application.studentDetails.firstNames +
    "\n\n" +
    "Your application status for the course: " +
    application.course.name + " has been updated." +
    "\n\n" +
    "STATUS BEFORE: " + oldStatus + "\n" +
    "STATUS UPDATE: " + application.status +
    "\n\n" +
    "Kind Regards" +
    "\n" +
    "FFSA Team";