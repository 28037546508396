/* eslint-disable react/prop-types */
import React, { useContext, useState } from "react";
import { isMobile } from "react-device-detect";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Dehaze from "@material-ui/icons/Dehaze";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

import styles from "assets/jss/material-kit-react/views/dashboard/admins/staffAdd.js";
import placeholder from "assets/img/faces/user.png";

//import appsettings from "appsettings.json";
import { CurrentUserContext } from "root/Store.js";
import {
  CoursesContext,
  StudentCourseContext
} from "views/Dashboard/root/Store.js";
import * as imagePicker from "shared/utils/imagePicker.js";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function StudentView(props) {
  const [courses] = useContext(CoursesContext);
  const [currentUser] = useContext(CurrentUserContext);
  const [studentCourse] = useContext(StudentCourseContext);
  const classes = useStyles();
  // eslint-disable-next-line react/prop-types
  const { isVisible, setVisible, student } = props;
  const [avatar, setAvatar] = useState("");
  const [updateInProgress, setUpdateInProgress] = useState(false);

  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );

  const studentCourseMappings = studentCourse.data.filter(
    sc => sc.studentId == student.id
  );
  const coursesDetails = courses.data.filter(c =>
    studentCourseMappings.map(sc => sc.courseId).includes(c.id)
  );

  return (
    <div className={classes.container}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal
            }}
            open={isVisible}
            style={{ minWidth: "100px" }}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setVisible(false)}
            aria-labelledby="classic-modal-slide-title"
            aria-describedby="classic-modal-slide-description"
          >
            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <IconButton
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={() => setVisible(false)}
              >
                <Close className={classes.modalClose} />
              </IconButton>
            </DialogTitle>
            <DialogContent
              id="classic-modal-slide-description"
              className={classes.modalBody}
            >
              <GridContainer
                style={{ marginBottom: "10px" }}
                id={"Primary Details"}
              >
                <GridItem
                  xs={4}
                  sm={4}
                  md={4}
                  onClick={() => {
                    if (currentUser.id == student.id || currentUser.isAdmin) {
                      imagePicker._updateUserAvatar(
                        setAvatar,
                        student,
                        setUpdateInProgress
                      );
                    }
                  }}
                >
                  {updateInProgress ? (
                    <CircularProgress style={{ margin: "20px" }} />
                  ) : (
                    <img
                      src={
                        avatar != ""
                          ? avatar
                          : student.avatar == ""
                          ? placeholder
                          : student.avatar
                      }
                      alt="..."
                      className={imageClasses}
                    />
                  )}
                </GridItem>
                <GridItem xs={8} sm={8} md={8} style={{ paddingTop: "10px" }}>
                  <strong>
                    {student.name} {student.surname}
                  </strong>
                  <br />
                  <span>{student.title} </span>
                </GridItem>
              </GridContainer>
              <GridContainer
                style={{ marginBottom: "10px", paddingTop: "10px" }}
                id={"Secondary Details"}
              >
                <GridItem xs={4} sm={4} md={4}>
                  <Dehaze
                    style={{
                      height: "100px",
                      width: "100px",
                      color: "#d1d5da"
                    }}
                  />
                </GridItem>
                <GridItem
                  xs={8}
                  sm={8}
                  md={8}
                  style={{
                    paddingTop: !student.suburb_township ? "20px" : "13px"
                  }}
                >
                  <div>{student.email}</div>
                  <div>{student.city}</div>
                  <div>{student.suburb_township}</div>
                </GridItem>
              </GridContainer>
              <GridContainer
                style={{ display: "block" }}
                id={"Courses Enrolled"}
              >
                <h4 style={{ textAlign: "center" }}>
                  <b>Courses</b>
                </h4>
                {coursesDetails.map((course, key) => (
                  <div
                    key={key}
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textAlign: "center"
                    }}
                  >
                    <SnackbarContent
                      key={1}
                      message={
                        <span
                          style={{
                            color: "black",
                            display: "inline-block",
                            textAlign: "center",
                            width: isMobile ? "200px" : "300px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis"
                          }}
                        >
                          {course.name}
                        </span>
                      }
                      color="#d1d5da"
                      icon="book"
                    />
                  </div>
                ))}
              </GridContainer>
            </DialogContent>
            <DialogActions className={classes.modalFooter}>
              <Button onClick={() => setVisible(false)} color="danger" simple>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </GridItem>
      </GridContainer>
    </div>
  );
}
