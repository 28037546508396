/* eslint-disable react/prop-types */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/coursesStyle.js";

import HeaderFooter from "../HeaderFooter.js";
import Qualification from "./Qualification";

const useStyles = makeStyles(styles);

export default function Courses(props) {
  const classes = useStyles();

  return (
    <HeaderFooter {...props}>
      <div id="courses" className={classes.section}>
        <h1 align="center" className={classes.title}>
          COURSES OFFERED
        </h1>
        <br />
        <Qualification
          color="#3CB371"
          qualification="DIPLOMA/HIGHER CERTIFICATE"
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <InfoArea
                title="DIPLOMA/HIGHER CERTIFICATE: Fashion Design Entrepreneurship & Retail (3 Years)"
                description="The Fezile Fashion Skills Academy offers a three year course in Fashion Design Entrepreneurship & Retail, aimed at the creative individual with a flair for fashion and an interest in the design and construction of a variety of garments.
                The emphasis lies on the creative and technical skills of design, patternmaking and garment construction in order to send innovative, competent and skilled individuals into the fashion industry
                General."
                borderColor="#3CB371"
              />
            </GridItem>
          </GridContainer>
        </Qualification>
        <Qualification
          color="rgb(19, 88, 131)"
          qualification="NATIONAL CERTIFICATE"
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <InfoArea
                title="NATIONAL CERTIFICATE: CLOTHING DESIGN DEVELOPMENT, ENTREPRENEURSHIP & RETAIL (2 Years)"
                description="This course covers a vast area in fashion which will groom you to become a sought-after clothing design specialist with skills in range building, pattern making, textiles, costing and garment construction. During this course you will be polished on your design aesthetic, fashion merchandising and entrepreneurship."
                borderColor="rgb(19, 88, 131)"
              />
            </GridItem>
          </GridContainer>
        </Qualification>
        <Qualification color="#e5a9ab" qualification="SHORT COURSES">
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <InfoArea
                title="SKILLS PROGRAMME CERTIFICATE: PATTERN SKILLS AND DESIGN SHORT COURSE"
                description="The Pattern Skills & Design module covers all aspects and fundamentals of Pattern Making. In this course you will learn the fundamentals of drafting various blocks according to sizing and silhouettes. You will become a master in manipulating style lines."
                borderColor="#e5a9ab"
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={6}>
              <InfoArea
                title="SKILLS PROGRAMME CERTIFICATE: GARMENT CONSTRUCTION AND TECHNOLOGY SHORT COURSE."
                description="After this module, you will be SEWING LIKE a PRO!!"
                borderColor="#e5a9ab"
              />
            </GridItem>
          </GridContainer>
        </Qualification>
      </div>
    </HeaderFooter>
  );
}
