export const htmlTableStart = "<table >";
export const htmlTableEnd = "</table>";
export const htmlTrStart = "<tr>";
export const htmlTrEnd = "</tr>";
export const htmlTdStart = "<td>";
export const htmlTdEnd = "</td>";
export const lineBreak = "<br>";
export const lineSkip = "<br><br>";

export const studentDetailsTitles = [
  "Surname:",
  "First Names:",
  "ID Number:",
  "Residential Address (Street):",
  "Residential Address (Suburb):",
  "Residential Address (Town):",
  "Residential Address (PostalCode):",
  "Postal Address (Street):",
  "Postal Address (Suburb):",
  "Postal Address (Town):",
  "Postal Address (PostalCode):",
  "Tel (H):",
  "Tel (W):",
  "Cell:",
  "Fax:",
  "Email:",
  "Title:",
  "Race:",
  "Gender:",
  "Other race if not included in the options:",
  "Do you suffer from any disabilities or medical conditions that may adversely affect your studies?",
  "If yes, please state the nature of the disability or condition:"
];

export const marketingDetailsTitles = [
  "How did you hear about the programme you are applying for at FEZILE FASHION SKILLS ACADEMY?:",
  "If Guidance counselor, please provide details:",
  "Name:",
  "Contact:"
];

export const parentOrGuardianTitles = [
  "Relationship:",
  "ID Number:",
  "Residential Address (Street):",
  "Residential Address (Suburb):",
  "Residential Address (Town):",
  "Residential Address (PostalCode):",
  "Postal Address (Street):",
  "Postal Address (Suburb):",
  "Postal Address (Town):",
  "Postal Address (PostalCode):",
  "Tel (H):",
  "Tel (W):",
  "Cell:",
  "Email:"
];

export const academicHistoryTitles = [
  "<b> a. High School Record </b>",
  "Last High School Attended",
  "Year:",
  "Country:",
  "Aggregate:",
  "Select the type of Senior Certificate:",
  "",
  "<b> b. Tertiary Study Record </b>",
  "If previous qualification, please provide details:",
  "Qualification Description:",
  "Institution:",
  "Total Credits:",
  "Year(s) of study:",
  "Completed:",
  "",
  "Qualification Description:",
  "Institution:",
  "Total Credits:",
  "Year(s) of study:",
  "Completed:",
  "",
  "Qualification Description:",
  "Institution:",
  "Total Credits:",
  "Year(s) of study:",
  "Completed:"
];

export const applicantTitles = [
  "Signature:",
  "Date:",
  "Witness:",
  "Date:",
  "Parent/Legal guardian:",
  "Date:"
];

export const benifactorTitles = ["Signature:", "Date:", "ID Number:"];
