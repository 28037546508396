/* eslint-disable react/prop-types */
import "./List.css";

import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
import AddBoxOutlined from "@material-ui/icons/AddBoxOutlined";
import Book from "@material-ui/icons/LibraryBooks";
import AccessTime from "@material-ui/icons/AccessTime";
import EditOutlined from "@material-ui/icons/EditOutlined";
import PaymentOutlined from "@material-ui/icons/PaymentOutlined";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import WhatsAppFloatingButton from "components/WhatsAppFloatingBtn";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { CurrentUserContext } from "root/Store.js";
import {
  CoursesContext,
  StudentCourseContext
} from "views/Dashboard/root/Store.js";
// Sections for this page
import Add from "./Add.js";
import Edit from "./Edit.js";
import EditFees from "./EditFees.js";
import colors from "shared/constants/colors.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function List(props) {
  const [courses] = useContext(CoursesContext);
  const [currentUser] = useContext(CurrentUserContext);
  const [studentCourse] = useContext(StudentCourseContext);
  const [course, setCourse] = useState({});
  const classes = useStyles();
  const { ...rest } = props;

  const studentCourseMappings = studentCourse.data.filter(
    sc => sc.studentId == currentUser.id
  );

  const [isAddVisible, setAddVisible] = useState(false);
  const [isEditVisible, setEditVisible] = useState(false);
  const [isEditFeesVisible, setEditFeesVisible] = useState(false);

  const redirect = courseId => {
    if (
      (currentUser.isAdmin) ||
      studentCourseMappings.some(scm => scm.courseId == courseId)
    ) props.history.push(`/course/${courseId}`);
    else toast.error("You are not registered for this course!");
  };

  console.log('currentUser', currentUser)
  useEffect(() => {
    if (!currentUser) props.history.push("/login");
  }, []);

  const editCourse = course => {
    setCourse(course);
    setEditVisible(true);
  };
  const editFees = course => {
    setCourse(course);
    setEditFeesVisible(true);
  };

  return (
    <div>
      <Header
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks {...props} />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "white"
        }}
        {...rest}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer style={{ height: "10px", paddingTop: "30px" }}>
            <GridItem xs={10} sm={10} md={10} />
            <GridItem xs={2} sm={2} md={2}>
              {currentUser && currentUser.isAdmin && (
                <AddBoxOutlined
                  style={{ color: "black" }}
                  onClick={() => setAddVisible(true)}
                />
              )}
              <Add isVisible={isAddVisible} setVisible={setAddVisible} />
              <EditFees
                course={course}
                isVisible={isEditFeesVisible}
                setVisible={setEditFeesVisible}
              />
              <Edit
                course={course}
                setCourse={setCourse}
                isVisible={isEditVisible}
                setVisible={setEditVisible}
              />
            </GridItem>
          </GridContainer>
          <GridContainer
            style={{
              height: "700px",
              paddingTop: "30px",
              overflow: "auto",
              color: "#56b383"
            }}
            justify={"center"}
          >
            {courses.data.length == 0 &&
              !courses.inProgress &&
              "No courses available!"}
            {courses.inProgress && (
              <CircularProgress style={{ margin: "50px" }} />
            )}
            {courses.data
              .sort((a, b) => b.createdAt - a.createdAt)
              .map((course, i) => (
                <GridItem
                  key={i}
                  xs={12}
                  sm={12}
                  md={8}
                  onClick={() => redirect(course.id)}
                >
                  <Card>
                    <CardHeader
                      style={{
                        ..._styles.cardHeader,
                        background: colors[course.color]
                      }}
                      stats
                      icon
                    >
                      <CardIcon
                        id="cardicon"
                        style={{
                          ..._styles.cardIcon,
                          background: colors[course.color]
                        }}
                      >
                        <Book />
                      </CardIcon>
                      {course.name}
                    </CardHeader>
                    <CardFooter
                      stats
                      style={{ justifyContent: "space-between" }}
                    >
                      <div className={classes.stats}>
                        <span style={{ display: "flex" }}>
                          <AccessTime />
                          <p style={{ marginTop: "3px" }}>{course.duration}</p>
                        </span>
                      </div>
                      {currentUser.isAdmin && (
                        <span
                          onClick={event => {
                            event.stopPropagation();
                            editFees(course);
                          }}
                          style={{ display: "flex" }}
                        >
                          <PaymentOutlined style={{ color: "green" }} />
                          <p style={{ marginTop: "3px" }}>Fees</p>
                        </span>
                      )}
                      {currentUser.isAdmin && (
                        <EditOutlined
                          onClick={event => {
                            event.stopPropagation();
                            editCourse(course);
                          }}
                        />
                      )}
                    </CardFooter>
                  </Card>
                </GridItem>
              ))}
          </GridContainer>
          <GridContainer style={{ height: "50px" }} />
        </div>
      </div>
      <WhatsAppFloatingButton />
      <Footer />
    </div>
  );
}

const _styles = {
  cardHeader: {
    textAlign: "center",
    boxShadow: "2px 2px 5px 0px rgba(0,0,0,0.5)",
    color: "#fff"
  },
  cardIcon: {
    padding: "15px",
    marginTop: "-20px",
    marginRight: "15px",
    borderRadius: "3px",
    backgroundColor: "#999",
    float: "left",
    boxShadow: "2px 2px 5px 0px rgba(0,0,0,0.5)",
    color: "#fff"
  }
};
