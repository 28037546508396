/* eslint-disable react/prop-types */
import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
import Note from "@material-ui/icons/Note";
import Edit from "@material-ui/icons/Edit";
import NoteAddOutlined from "@material-ui/icons/NoteAddOutlined";
import CreateNewFolder from "@material-ui/icons/CreateNewFolder";
import People from "@material-ui/icons/People";
import PersonAdd from "@material-ui/icons/PersonAdd";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import Notifications from "@material-ui/icons/Notifications";
import AddAlertOutlined from "@material-ui/icons/AddAlertOutlined";
import ChatIcon from "@material-ui/icons/Chat";
import VideocamOutlined from "@material-ui/icons/VideocamOutlined";
import VideoCallOutlined from "@material-ui/icons/VideoCallOutlined";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/CustomParallax.js";

import profile from "assets/img/logo.png";
import styles from "assets/jss/material-kit-react/views/dashboard/admins/adminsPage.js";

import EditDescriptionModal from "./EditDescription.js";
import StudentsList from "./Students/List.js";
import StudentsAdd from "./Students/Add.js";
import DocumentsContent from "./Documents/View.js";
import VideosContent from "./Videos/View.js";
import Announcements from "./Announcements/List.js";
import AddAnnouncements from "./Announcements/Add.js";
import Chat from "./Chat.js";

import { CurrentUserContext } from "root/Store.js";
import {
  CoursesContext,
  StudentCourseContext,
  UsersContext,
  DocumentsContext,
  AnnouncementsContext,
  MessagesContext
} from "views/Dashboard/root/Store.js";
import { paragraph } from "shared/utils/stringExtentions.js";
import * as documentsUtils from "./Documents/utils.js";

const useStyles = makeStyles(styles);

export default function Admins(props) {
  const [currentUser] = useContext(CurrentUserContext);
  const [courses] = useContext(CoursesContext);
  const [studentCourse] = useContext(StudentCourseContext);
  const [users] = useContext(UsersContext);
  const [announcements] = useContext(AnnouncementsContext);
  const [messages] = useContext(MessagesContext);
  const [, dispatch] = useContext(DocumentsContext);
  const course = courses.data.find(c => c.id == props.match.params.id);
  const courseId = course && course.id;

  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );

  const [section, setSection] = useState("description");
  const [EditDescription, setEditDescription] = useState(false);
  const [AddstudentsVisible, showAddstudents] = useState(false);
  const [AddAnnouncementVisible, showAddAnnouncement] = useState(false);

  // Documents section
  const [documentsSection, setDocumentsSection] = useState("folders");
  const [addFolderVisible, setAddFolderVisible] = useState(false);
  const [documentsNavigation, setDocumentsNavigation] = useState({
    courseId: courseId
  });

  // Videos section
  const [videosSection, setVideosSection] = useState("folders");
  const [addVideosFolderVisible, setAddVideosFolderVisible] = useState(false);
  const [addVideoVisible, setAddVideoVisible] = useState(false);
  const [videosNavigation, setVideosNavigation] = useState({
    courseId: courseId
  });

  const studentCourseMappings = studentCourse.data.filter(
    sc => sc.studentId == currentUser.id
  );
  const studentsCourseEnrolled = studentCourse.data.filter(
    sc => sc.courseId == courseId
  );
  const studentsEnrolled = users.data.filter(u =>
    studentsCourseEnrolled.some(sce => sce.studentId == u.id)
  );

  useEffect(() => {
    if (
      !(
        currentUser &&
        (studentCourseMappings.some(scm => scm.courseId == courseId) ||
          currentUser.isAdmin)
      )
    )
      props.history.push("/dashboard");
  }, []);

  return (
    <div>
      <Header
        rightLinks={<HeaderLinks {...props} />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/profile-bg.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>
                      Fezile Fashion Skills Academy
                    </h3>
                    <h6>{course && course.name}</h6>
                  </div>
                </div>
                <div style={{ textAlign: "center", marginTop: 10 }}>
                  <Link
                    to="/courses"
                    style={{ color: "rgb(86, 179, 131)" }}
                    className={classes.navLink}
                  >
                    Courses
                  </Link>
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                <NavPills
                  alignCenter
                  color="primary"
                  tabs={[
                    {
                      onClick: () =>
                        section == "description"
                          ? currentUser.isAdmin && setEditDescription(true)
                          : setSection("description"),
                      tabButton: "Description",
                      tabIcon: section == "description" ? Edit : Note,
                      disabled: courses.inProgress,
                      tabContent: (
                        <GridContainer justify="center">
                          {courses.inProgress ? (
                            <CircularProgress style={{ margin: "50px" }} />
                          ) : (
                            <GridItem
                              xs={12}
                              sm={6}
                              md={6}
                              style={{ textAlign: "start", height: "500px" }}
                            >
                              <div>
                                {course && paragraph(course.description)}
                                <EditDescriptionModal
                                  course={course}
                                  courseId={courseId}
                                  isVisible={EditDescription}
                                  setVisible={setEditDescription}
                                />
                              </div>
                            </GridItem>
                          )}
                        </GridContainer>
                      )
                    },
                    {
                      onClick: () =>
                        section == "students"
                          ? currentUser.isAdmin && showAddstudents(true)
                          : setSection("students"),
                      tabButton: "Students",
                      tabIcon: section == "students" ? PersonAdd : People,
                      tabContent: (
                        <GridContainer justify="center">
                          {users.inProgress ? (
                            <CircularProgress style={{ margin: "50px" }} />
                          ) : (
                            <GridItem xs={12} sm={6} md={6}>
                              <div>
                                <StudentsList courseId={courseId} />
                                <StudentsAdd
                                  courseId={courseId}
                                  isVisible={AddstudentsVisible}
                                  setVisible={showAddstudents}
                                />
                              </div>
                            </GridItem>
                          )}
                        </GridContainer>
                      )
                    },
                    {
                      onClick: () =>
                        section === "documents" && currentUser.isAdmin
                          ? documentsSection === "documents"
                            ? documentsUtils.uploadDocument(
                                studentsEnrolled,
                                documentsNavigation.subFolderId,
                                dispatch
                              )
                            : setAddFolderVisible(true)
                          : setSection("documents"),
                      tabButton: "Documents",
                      tabIcon:
                        section === "documents"
                          ? documentsSection === "documents"
                            ? NoteAddOutlined
                            : CreateNewFolder
                          : LibraryBooks,
                      tabContent: (
                        <DocumentsContent
                          navigation={documentsNavigation}
                          setNavigation={setDocumentsNavigation}
                          section={documentsSection}
                          setSection={setDocumentsSection}
                          addFolderVisible={addFolderVisible}
                          setAddFolderVisible={setAddFolderVisible}
                        />
                      )
                    },
                    {
                      onClick: () =>
                        section == "announcements"
                          ? currentUser.isAdmin && showAddAnnouncement(true)
                          : setSection("announcements"),
                      tabButton: "Announcements",
                      tabIcon:
                        section == "announcements"
                          ? AddAlertOutlined
                          : Notifications,
                      tabContent: (
                        <GridContainer justify="center">
                          {announcements.inProgress ? (
                            <CircularProgress style={{ margin: "50px" }} />
                          ) : (
                            <GridItem xs={12} sm={12} md={12}>
                              <Announcements courseId={courseId} />
                              <AddAnnouncements
                                students={studentsEnrolled}
                                courseId={courseId}
                                isVisible={AddAnnouncementVisible}
                                setVisible={showAddAnnouncement}
                              />
                            </GridItem>
                          )}
                        </GridContainer>
                      )
                    },
                    {
                      onClick: () => setSection("chatroom"),
                      tabButton: "Chat Room",
                      tabIcon: ChatIcon,
                      tabContent: (
                        <GridContainer justify="center">
                          {messages.inProgress ? (
                            <CircularProgress style={{ margin: "50px" }} />
                          ) : (
                            <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                              style={{ height: "500px" }}
                            >
                              <Chat courseId={courseId} />
                            </GridItem>
                          )}
                        </GridContainer>
                      )
                    },
                    {
                      onClick: () =>
                        section === "videos" && currentUser.isAdmin
                          ? videosSection === "videos"
                            ? setAddVideoVisible(true)
                            : setAddVideosFolderVisible(true)
                          : setSection("videos"),
                      tabButton: "Videos",
                      tabIcon:
                        section === "videos"
                          ? videosSection === "videos"
                            ? VideoCallOutlined
                            : CreateNewFolder
                          : VideocamOutlined,
                      tabContent: (
                        <VideosContent
                          navigation={videosNavigation}
                          setNavigation={setVideosNavigation}
                          section={videosSection}
                          setSection={setVideosSection}
                          addFolderVisible={addVideosFolderVisible}
                          setAddFolderVisible={setAddVideosFolderVisible}
                          addVideoVisible={addVideoVisible}
                          setAddVideoVisible={setAddVideoVisible}
                        />
                      )
                    }
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
