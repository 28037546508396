/* eslint-disable react/prop-types */
import "./styles.scss";

import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-react/components/infoStyle.js";

const useStyles = makeStyles(styles);

export default function InfoArea(props) {
  const [isDestriptionVisible, setDestriptionVisible] = React.useState(false);
  const classes = useStyles();
  const { title, description, borderColor } = props;
  return (
    <div
      onMouseEnter={() => setDestriptionVisible(true)}
      onClick={() => setDestriptionVisible(true)}
      onMouseLeave={() => setDestriptionVisible(false)}
      className={classes.infoArea}
      style={{
        border: `5px solid ${borderColor}`,
        borderRadius: 10,
        padding: 10,
        marginBottom: 10
      }}
    >
      <div className={classes.descriptionWrapper}>
        <h4 className={classes.title}>{title}</h4>
        <p
          className={classNames(
            "description",
            `${isDestriptionVisible ? "show" : "hide"}`
          )}
        >
          {description}
        </p>
      </div>
    </div>
  );
}

InfoArea.defaultProps = {
  iconColor: "gray"
};

InfoArea.propTypes = {
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  iconColor: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  vertical: PropTypes.bool
};
