/* eslint-disable react/prop-types */
import React, { useContext, useState } from "react";

// @material-ui/core components
import Slide from "@material-ui/core/Slide";
import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import GridItem from "components/Grid/GridItem.js";

import { CurrentUserContext } from "root/Store.js";
import { ReceiptsContext } from "views/Dashboard/root/Store.js";
import * as sendApplicationEmails from "views/LandingPage/Sections/Registration/shared/utils/sendApplicationEmails";
import { uploadReceipt } from "shared/utils/files";
import { stringToKey } from "shared/utils/convertions";
import { setData } from "api";
import { update } from "api";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function Receipt({ fee, application }) {
  const [receipts] = useContext(ReceiptsContext);
  const [currentUser] = useContext(CurrentUserContext);
  const [inProgress, setInProgress] = useState(false);

  const userReceipts = receipts.data.filter(
    r => r.userId == application.applicantID
  );

  const onReceiptUpload = async file => {
    setInProgress(true);

    const fee = JSON.parse(localStorage.getItem("receipt-fee"));

    if (file.name) {
      const fileUrl = await uploadReceipt(
        file,
        stringToKey(`${application.course.name}_${currentUser.name}`)
      );

      //Send application details to admin if not yet sent
      if (!application.applicationDetailsSent) {
        sendApplicationEmails.sendApplicationForAdmission(
          application,
          "Application For Admission: Paid Online"
        );

        update("applications", application.id, {
          status: "Awaiting review",
          applicationDetailsSent: true
        });
      }

      //Send fee reciept upload notification
      sendApplicationEmails.sendApplicationFeeReceiptEmail(
        application,
        fee,
        file
      );

      //Save reciept details
      setData("receipts", {
        feeId: fee.id,
        feeName: fee.name,
        userId: currentUser.id,
        url: fileUrl,
        name: file.name,
        applicationId: application.id
      });

      setInProgress(false);
    }
  };

  return (
    <GridItem xs={2} sm={2} md={2} lg={2}>
      {inProgress &&
      JSON.parse(localStorage.getItem("receipt-fee")).id == fee.id ? (
        <CircularProgress style={_styles.inProgress} />
      ) : (
        <>
          <input
            type="file"
            id="file"
            style={{ display: "none" }}
            onChange={event => onReceiptUpload(event.target.files[0])}
          />
          {userReceipts.some(ur => ur.feeId == fee.id) ? (
            <label
              onClick={() =>
                window.open(userReceipts.find(ur => ur.feeId == fee.id).url)
              }
              style={{
                ..._styles.reciept,
                color: "white",
                backgroundColor: "#56b383"
              }}
            >
              RECEIPT
            </label>
          ) : (
            <label
              htmlFor="file"
              onClick={() =>
                localStorage.setItem("receipt-fee", JSON.stringify(fee))
              }
              style={_styles.reciept}
            >
              RECEIPT
            </label>
          )}
        </>
      )}
    </GridItem>
  );
}

const _styles = {
  reciept: {
    border: "1px solid",
    borderRadius: "5px",
    padding: "12px 30px",
    marginTop: "33px",
    justifyContent: "center",
    display: "flex"
  },
  inProgress: {
    marginTop: "33px",
    marginLeft: "20px"
  }
};
