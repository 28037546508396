import React, { useContext } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";

// core components
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

import styles from "assets/jss/material-kit-react/views/dashboard/admins/staffPage.js";

import { DocumentsContext } from "views/Dashboard/root/Store.js";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function Deocuments() {
  const [documents] = useContext(DocumentsContext);
  const classes = useStyles();
  const data = documents.data.filter(doc => doc.parentId == "students");

  return (
    <div
      className={classes.section}
      id="notifications"
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
      }}
    >
      <div className={classes.container} style={{ maxHeight: "500px" }}>
        {data
          .sort((a, b) => b.createdAt - a.createdAt)
          .map((doc, key) => (
            <span key={key} onClick={() => window.open(doc.url, "_blank")}>
              <SnackbarContent
                message={
                  <span style={{ color: "black" }}>
                    <b>{doc.name}</b>
                  </span>
                }
                color="black"
                icon="picture_as_pdf_outline"
              />
            </span>
          ))}
        <div center style={{ marginTop: 10 }}>
          {data.length == 0 && "No documents."}
        </div>
      </div>
    </div>
  );
}
