import React, { useReducer, useEffect } from "react";
import firebase from "firebase";

import * as api from "api/index.js";
import appsettings from "appsettings.json";
import * as rootReducer from "shared/utils/rootReducer";

export const FoldersContext = React.createContext();
export const VideosContext = React.createContext();

const initalState = {
  data: [],
  search: "",
  inProgress: true
};

if (!firebase.apps.length)
  firebase.initializeApp(appsettings[appsettings.environment].firebaseConfig);

// eslint-disable-next-line react/prop-types
const Store = ({ children }) => {
  const [folders, dispatchFolders] = useReducer(
    rootReducer.setStateReducer,
    initalState
  );
  const [videos, dispatchVideos] = useReducer(
    rootReducer.setStateReducer,
    initalState
  );

  useEffect(() => {
    api.getCollection("folders", dispatchFolders);
    api.getCollection("videos", dispatchVideos);
  }, []);

  return (
    <FoldersContext.Provider value={[folders, dispatchFolders]}>
      <VideosContext.Provider value={[videos, dispatchVideos]}>
        {children}
      </VideosContext.Provider>
    </FoldersContext.Provider>
  );
};
export default Store;
