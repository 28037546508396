import React, { useContext, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/dashboard/admins/staffPage.js";
import placeholder from "assets/img/faces/user.png";

import {
  UsersContext,
  StudentCourseContext
} from "views/Dashboard/root/Store.js";

import StudentView from "./View.js";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function Students(props) {
  const [users] = useContext(UsersContext);
  const [studentCourse] = useContext(StudentCourseContext);
  const [isProfileVisible, setProfileVisible] = useState(false);
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const [profile, setProfile] = useState({});
  const studentsOnThisCourse = studentCourse.data.filter(
    // eslint-disable-next-line react/prop-types
    sc => sc.courseId == props.courseId
  );
  const data = users.data.filter(s =>
    studentsOnThisCourse.map(es => es.studentId).includes(s.id)
  );

  const profileView = profile => {
    setProfile(profile);
    setProfileVisible(true);
  };

  return (
    <div className={classes.section}>
      <div className={classes.container} style={{ maxHeight: "500px" }}>
        {data.length == 0 && "No students added on this course."}
        <StudentView
          student={profile}
          setVisible={setProfileVisible}
          isVisible={isProfileVisible}
        />
        {data
          .sort((a, b) => b.createdAt - a.createdAt)
          .map((student, key) => (
            <GridContainer
              key={key}
              style={{ marginBottom: "10px" }}
              onClick={() => profileView(student)}
            >
              <GridItem xs={4} sm={4} md={4} row>
                <img
                  src={student.avatar == "" ? placeholder : student.avatar}
                  alt="..."
                  className={imageClasses}
                />
              </GridItem>
              <GridItem xs={8} sm={8} md={8} style={{ paddingTop: "10px" }}>
                <strong>
                  {student.name} {student.surname}
                </strong>
                <br />
                <span>{student.title} </span>
              </GridItem>
            </GridContainer>
          ))}
      </div>
    </div>
  );
}
