/* eslint-disable react/prop-types */
import React, { useState, useContext } from "react";
import { Wizard } from "@patternfly/react-core";

import * as api from "api/index.js";
import { CurrentUserContext } from "root/Store.js";

import Steps from "./RegistrationSteps";
import applicationDetailsValidation from "./shared/utils/validations";
import * as sendApplicationEmails from "./shared/utils/sendApplicationEmails.js";

const Registration = props => {
  const [currentUser] = useContext(CurrentUserContext);
  const { isVisible, setVisible } = props;
  const [studentDetails, setStudentDetails] = useState({
    surname: "",
    firstNames: "",
    idNumber: "",
    residentialAddress: {
      line1: "",
      line2: "",
      line3: "",
      postalCode: ""
    },
    postalAddress: {
      line1: "",
      line2: "",
      line3: "",
      postalCode: ""
    },
    telWork: "",
    telHome: "",
    cell: "",
    fax: "",
    email: "",
    title: "",
    race: "",
    otherRace: "",
    gender: "",
    disabilitiesOrMedicalConditionAffectStudies: "",
    natureOfDisabilityOrMedicalCondition: ""
  });

  const [marketingDetails, setMarketingDetails] = useState({
    id: "",
    marketingMedia: "",
    marketingMediaGuidanceConsellor: {
      name: "",
      contact: ""
    }
  });

  const [course, setCourse] = useState({
    id: "",
    name: ""
  });

  const [parentOrGuardianDetails, setParentOrGuardianDetails] = useState({
    relationship: "",
    idNumber: "",
    telWork: "",
    telHome: "",
    cell: "",
    email: "",
    residentialAddress: {
      line1: "",
      line2: "",
      line3: "",
      postalCode: ""
    },
    postalAddress: {
      line1: "",
      line2: "",
      line3: "",
      postalCode: ""
    }
  });

  const [academicHistory, setAcademicHistory] = useState({
    highSchoolRecord: {
      lastHighSchoolAttended: "",
      year: "",
      country: "",
      aggregate: "",
      highSchoolSeniorCertificateId: "",
      highSchoolSeniorCertificate: ""
    },
    tertiaryStudyRecord: {
      tertiaryYear1Record: {
        qualificationDescription: "",
        institution: "",
        totalCredits: "",
        yearsOfStudy: "",
        completedId: "",
        completed: ""
      },
      tertiaryYear2Record: {
        qualificationDescription: "",
        institution: "",
        totalCredits: "",
        yearsOfStudy: "",
        completedId: "",
        completed: ""
      },
      tertiaryYear3Record: {
        qualificationDescription: "",
        institution: "",
        totalCredits: "",
        yearsOfStudy: "",
        completedId: "",
        completed: ""
      }
    }
  });

  const [declarationDetails, setDeclarationDetails] = useState({
    applicantDeclaration: {
      fullName: "",
      IdorPassportNumber: "",
      applicantSignature: {
        signatureInitials: "",
        date: new Date().toDateString()
      },
      witnessSignature: {
        signatureInitials: "",
        date: new Date().toDateString()
      },
      parentOrGuardianDetailsSignature: {
        signatureInitials: "",
        date: new Date().toDateString()
      }
    },
    benifactorDeclaration: {
      fullName: "",
      idNumber: "",
      signature: {
        signatureInitials: "",
        date: new Date().toDateString()
      }
    }
  });

  return (
    <React.Fragment>
      <Wizard
        isOpen={isVisible}
        onSave={() => {
          var application = {
            studentDetails: studentDetails,
            marketing: marketingDetails,
            course: course,
            parentOrGuardianDetails: parentOrGuardianDetails,
            academicHistory: academicHistory,
            declaration: declarationDetails
          };
          var validationResults = applicationDetailsValidation(application);
          if (validationResults) {
            setVisible(false);

            sendApplicationEmails.sendApplicationAwaitingPaymentEmail(
              application
            );

            sendApplicationEmails.sendApplicationForAdmission(
              application,
              "Application For Admission"
            );

            api.setData("applications", {
              ...application,
              color: course.color,
              status: "Awaiting payment",
              applicantID: currentUser.id
            });

            props.history.push("/applications");
          }
        }}
        onClose={() => setVisible(false)}
        title={"Online Registration"}
        description={"Application For Admission"}
        steps={Steps({
          studentDetails: studentDetails,
          setStudentDetails: setStudentDetails,
          marketingDetails: marketingDetails,
          setMarketingDetails: setMarketingDetails,
          course: course,
          setCourse: setCourse,
          parentOrGuardianDetails: parentOrGuardianDetails,
          setParentOrGuardianDetails: setParentOrGuardianDetails,
          academicHistory: academicHistory,
          setAcademicHistory: setAcademicHistory,
          declarationDetails: declarationDetails,
          setDeclarationDetails: setDeclarationDetails
        })}
      />
    </React.Fragment>
  );
};

export default Registration;
