import React, { useState, useContext } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-kit-react/views/dashboard/admins/editDescription.js";

import { UsersContext } from "views/Dashboard/root/Store";
import * as api from "../../api/index";
import * as mailApi from "api/emailApi";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function EditDescriptionModal(props) {
  const [users] = useContext(UsersContext);
  const [message, setMessage] = useState("");
  const classes = useStyles();
  // eslint-disable-next-line react/prop-types
  const { isVisible, setVisible } = props;
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} lg={4}>
            <Dialog
              classes={{
                root: classes.center,
                paper: classes.modal
              }}
              open={isVisible}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => setVisible(false)}
              aria-labelledby="classic-modal-slide-title"
              aria-describedby="classic-modal-slide-description"
            >
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <IconButton
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={() => setVisible(false)}
                >
                  <Close className={classes.modalClose} />
                </IconButton>
                <h4 align={"center"} className={classes.modalTitle}>
                  Announcement
                </h4>
              </DialogTitle>
              <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
              >
                <CustomInput
                  labelText="Type an announcement..."
                  id="announcement"
                  value={message}
                  onChangeValue={value => setMessage(value)}
                  formControlProps={{
                    fullWidth: true
                  }}
                  rows={10}
                  multiline={true}
                />
              </DialogContent>
              <DialogActions className={classes.modalFooter}>
                <Button
                  color="success"
                  disabled={message === ""}
                  simple
                  onClick={() => {
                    api.setData("announcements", {
                      message: message,
                      parentId: "ffsa"
                    });
                    setVisible(false);
                    mailApi.sendMail({
                      bcc: users.data.filter(u => u.isAdmin).map(h => h.email),
                      message: {
                        subject: "FFSA Admins Announcement",
                        text: message
                      }
                    });
                  }}
                >
                  Send
                </Button>
                <Button onClick={() => setVisible(false)} color="danger" simple>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
