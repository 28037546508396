import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import LocationOn from "@material-ui/icons/LocationOn";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

import images from "./constants";
import HeaderFooter from "../HeaderFooter.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/campusesStyle.js";

const useStyles = makeStyles(styles);

export default function StudentLife(props) {
  const classes = useStyles();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };
  return (
    <HeaderFooter {...props}>
      <div id="student-life" className={classes.section}>
        <h1 align="center" className={classes.title}>
          STUDENT LIFE
        </h1>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} className={classes.marginAuto}>
              <Card carousel>
                <Carousel {...settings}>
                  {images.map((image, index) => (
                    <div key={index}>
                      <img
                        src={image}
                        alt="First slide"
                        className="slick-image"
                      />
                    </div>
                  ))}
                </Carousel>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </HeaderFooter>
  );
}
