import * as SystemConstants from "./constants.js";

export const construcApplicationDetailsEmailBody = application => {
  var message = "Hi  Team" + SystemConstants.lineSkip;
  message +=
    "This email consists of student application details please keep in touch with the student asap." +
    SystemConstants.lineSkip;

  message += constructStudentDetailsBodySection(application.studentDetails);
  message += constructMarketingDetailsBodySection(application.marketing);
  message += constructCourseDetailsBodySection(application.course.name);
  message += constructParentOrGuardianDetailsBodySection(
    application.parentOrGuardianDetails
  );
  message += constructAcademicHistoryBodySection(application.academicHistory);
  message += constructDeclarationBodySection(application.declaration);

  message += "Kind Regards" + SystemConstants.lineBreak + "Client Support";

  return message;
};

const constructStudentDetailsBodySection = studentDetails => {
  const values = [
    studentDetails.surname,
    studentDetails.firstNames,
    studentDetails.idNumber,
    studentDetails.residentialAddress.line1,
    studentDetails.residentialAddress.line2,
    studentDetails.residentialAddress.line3,
    studentDetails.residentialAddress.postalCode,
    studentDetails.postalAddress.line1,
    studentDetails.postalAddress.line2,
    studentDetails.postalAddress.line3,
    studentDetails.postalAddress.postalCode,
    studentDetails.telHome,
    studentDetails.telWork,
    studentDetails.cell,
    studentDetails.fax,
    studentDetails.email,
    studentDetails.title,
    studentDetails.race,
    studentDetails.gender,
    studentDetails.otherRace,
    studentDetails.disabilitiesOrMedicalConditionAffectStudies,
    studentDetails.natureOfDisabilityOrMedicalCondition
  ];

  return ConstructTable(
    SystemConstants.studentDetailsTitles,
    values,
    "1. STUDENT DETAILS"
  );
};

const ConstructTable = (tableTiles, values, header) => {
  var bodySection = "<b>" + header + "</b>" + SystemConstants.lineSkip;

  bodySection += SystemConstants.htmlTableStart;

  for (var index = 0; index < tableTiles.length; index += 1) {
    bodySection += SystemConstants.htmlTrStart;
    bodySection +=
      SystemConstants.htmlTdStart +
      tableTiles[index] +
      SystemConstants.htmlTdEnd;
    bodySection +=
      SystemConstants.htmlTdStart + values[index] + SystemConstants.htmlTdEnd;
    bodySection += SystemConstants.htmlTrEnd;
  }

  bodySection = bodySection + SystemConstants.htmlTableEnd;

  return bodySection + SystemConstants.lineSkip;
};

const constructMarketingDetailsBodySection = marketingDetails => {
  var values = [
    marketingDetails.marketingMedia,
    "",
    marketingDetails.marketingMediaGuidanceConsellor.name,
    marketingDetails.marketingMediaGuidanceConsellor.contact
  ];

  return ConstructTable(
    SystemConstants.marketingDetailsTitles,
    values,
    "2. MARKETING"
  );
};

const constructCourseDetailsBodySection = course => {
  const CourseDetailsTitles = ["Select course:"];
  const values = [course];

  return ConstructTable(CourseDetailsTitles, values, "3. COURSE APPLYING FOR");
};

const constructParentOrGuardianDetailsBodySection = parentOrGuardianDetails => {
  const values = [
    parentOrGuardianDetails.relationship,
    parentOrGuardianDetails.idNumber,
    parentOrGuardianDetails.residentialAddress.line1,
    parentOrGuardianDetails.residentialAddress.line2,
    parentOrGuardianDetails.residentialAddress.line3,
    parentOrGuardianDetails.residentialAddress.postalCode,
    parentOrGuardianDetails.postalAddress.line1,
    parentOrGuardianDetails.postalAddress.line2,
    parentOrGuardianDetails.postalAddress.line3,
    parentOrGuardianDetails.postalAddress.postalCode,
    parentOrGuardianDetails.telHome,
    parentOrGuardianDetails.telWork,
    parentOrGuardianDetails.cell,
    parentOrGuardianDetails.email
  ];

  return ConstructTable(
    SystemConstants.parentOrGuardianTitles,
    values,
    "4. PARENT/GUARDIAN"
  );
};

const constructAcademicHistoryBodySection = academicHistory => {
  const values = [
    "",
    academicHistory.highSchoolRecord.lastHighSchoolAttended,
    academicHistory.highSchoolRecord.year,
    academicHistory.highSchoolRecord.country,
    academicHistory.highSchoolRecord.aggregate,
    academicHistory.highSchoolRecord.highSchoolSeniorCertificate,
    "",
    "",
    "",
    academicHistory.tertiaryStudyRecord.tertiaryYear1Record
      .qualificationDescription,
    academicHistory.tertiaryStudyRecord.tertiaryYear1Record.institution,
    academicHistory.tertiaryStudyRecord.tertiaryYear1Record.totalCredits,
    academicHistory.tertiaryStudyRecord.tertiaryYear1Record.yearsOfStudy,
    academicHistory.tertiaryStudyRecord.tertiaryYear1Record.completed,
    "",
    academicHistory.tertiaryStudyRecord.tertiaryYear2Record
      .qualificationDescription,
    academicHistory.tertiaryStudyRecord.tertiaryYear2Record.institution,
    academicHistory.tertiaryStudyRecord.tertiaryYear2Record.totalCredits,
    academicHistory.tertiaryStudyRecord.tertiaryYear2Record.yearsOfStudy,
    academicHistory.tertiaryStudyRecord.tertiaryYear2Record.completed,
    "",
    academicHistory.tertiaryStudyRecord.tertiaryYear3Record
      .qualificationDescription,
    academicHistory.tertiaryStudyRecord.tertiaryYear3Record.institution,
    academicHistory.tertiaryStudyRecord.tertiaryYear3Record.totalCredits,
    academicHistory.tertiaryStudyRecord.tertiaryYear3Record.yearsOfStudy,
    academicHistory.tertiaryStudyRecord.tertiaryYear3Record.completed
  ];

  return ConstructTable(
    SystemConstants.academicHistoryTitles,
    values,
    "5. ACADEMIC HISTORY"
  );
};

const constructDeclarationBodySection = declaration => {
  var declarationBody =
    "<b>6. DECLARATION (Compulsory)</b>" + SystemConstants.lineSkip;

  const applicantValues = [
    declaration.applicantDeclaration.applicantSignature.signatureInitials,
    declaration.applicantDeclaration.applicantSignature.date,
    declaration.applicantDeclaration.witnessSignature.signatureInitials,
    declaration.applicantDeclaration.witnessSignature.date,
    declaration.applicantDeclaration.parentOrGuardianDetailsSignature
      .signatureInitials,
    declaration.applicantDeclaration.parentOrGuardianDetailsSignature.date
  ];

  const benifactorValues = [
    declaration.benifactorDeclaration.signature.signatureInitials,
    declaration.benifactorDeclaration.signature.date,
    declaration.benifactorDeclaration.idNumber
  ];

  declarationBody +=
    "I, <b><u>   " +
    declaration.applicantDeclaration.fullName +
    "   </u></b> (Full Name)," +
    "ID/Passport Number: <b><u>   " +
    declaration.applicantDeclaration.idorPassportNumber +
    "   </u></b> , " +
    "the undersigned, declare that all the particulars supplied by me in this form are true, " +
    "complete and correct. I accept that incorrect or misleading information could lead to the cancellation of this application." +
    SystemConstants.lineSkip;

  declarationBody += ConstructTable(
    SystemConstants.applicantTitles,
    applicantValues,
    "Applicant"
  );

  declarationBody +=
    "<i>This section must be completed by the benefactor, i.e. the person who will be responsible for the payment of all tuition fees.</i>" +
    SystemConstants.lineSkip;

  declarationBody +=
    "I, <b><u>   " +
    declaration.benifactorDeclaration.fullName +
    "   </u></b> (Full Name), the undersigned, hereby acknowledge myself to be jointly and separately responsible for monies, " +
    "which the above mentioned applicant may at any stage be owing to FFSA (Pty) Ltd in terms of the agreement that he/she concluded with FFSA (Pty) Ltd, as set out above, including any change thereto." +
    SystemConstants.lineSkip;

  return (declarationBody += ConstructTable(
    SystemConstants.benifactorTitles,
    benifactorValues,
    "Parent/Guardian/Benefactor"
  ));
};

export default construcApplicationDetailsEmailBody;
